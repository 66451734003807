.ForTContr {
    background: linear-gradient(rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.4)), url("../../images/tconr.jpg");
    background-size: cover;
    min-height: 1200px;
    background-position: center;
    background-repeat: no-repeat;
    background-attachment: fixed;

    &__title{
        color: #fff;
        padding-top: 150px;
        text-align: center;
        font-size: 70px;
    }
    &__inf{
        color: #fff;
        padding-top: 100px;
        font-size: 18px;
        max-width: 750px;
        margin-left: auto;
        text-indent: 25px;
        text-align: left;
        font-style: italic;
    }
    &__block1{
        background-color: #fff;
        margin-top: 150px;
        max-width: 100%;
        max-height: 500px;
    }
    &__block1-describe1{
        line-height: 30px;
        padding-top: 30px;
        padding-bottom: 30px;
        font-size: 25px;
        text-align: justify;
    }
    &__block2{
        background-color: #fff;
        margin-top: 150px;
        padding-bottom: 10px;
        max-width: 100%;
        max-height: 500px;
    }
    &__block2-describe2{
        padding-top: 30px;
        padding-bottom: 10px;
        font-size: 25px;
        text-align: justify;
        margin-bottom: 50px;
        line-height: 30px;
    }
    &__ForMargin{
        height: 120px;
        background-color: #fff

    }
}