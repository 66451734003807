.RSODynamic_worker_create_smena{
  display: flex;
  flex-direction: column;
  align-items: center;
  .title{
    margin-bottom: 40px;
    font-weight: bold;
  }
  &__form{
    .RSODynamicButton{
      margin: 0 auto;
    }
  }
}