/* Стили для основных кнопок */
.conteiner_btn_addwear {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    margin-top: 30px;
    margin-bottom: 20px;
  }
  
  .btn_addwear_wear {
    background-color: #1f487e;
    color: white;
    width: 15%;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
    padding: 10px 20px;
    margin: 10px;
    border-radius: 8px;
    cursor: pointer;
    transition: all 0.3s ease;
    font-size: 20px;
  }
  
  .btn_addwear_wear:hover {
    opacity: 0.8;
  }
  
  .btn_addwear_wear:active {
    transform: translateY(10px);
  }
  
  /* Стили для полей ввода */
  
  .wear-input-container-addwear {
    width: 80%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
    margin-bottom: 10px;
  }
  
  .wear-input-addwear {
    width: 100%;
    padding: 20px; /* Увеличиваем внутренний отступ */
    margin: 15px 10px; /* Увеличиваем внешний отступ снизу и оставляем отступ слева/справа как раньше */
    border: 1px solid #777777; /* Добавляем серую обводку */
    border-radius: 5px;
    box-sizing: border-box;
  }
  
  /* Добавленные стили для полей ввода и таблицы*/
  .wear-container-table-btn {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;

  }
  
  .wear-table {
    border-collapse: collapse;
    width: 80%;
    margin-bottom: 10px;
    background-color: #ffffff;
  }
  
  .wear-row {
    border: 1px solid #000000;
  }
  
  .wear-cell {
    border: 1px solid #000000;
    padding: 8px;
    text-align: center;
  }
  
  .wear-buttons {
    display: flex;
    justify-content: center;
    width: 100%;
  }

  
  /* Стили для дополнительных кнопок */
  
  .conteiner_btn_addwear_hold_wear {
    display: flex;
    justify-content: center;
    flex-direction: row;
  }
  
  .btn_addwear_hold_wear {
    background-color: #1f487e;
    color: white;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
    padding: 10px 20px;
    margin: 10px;
    border-radius: 8px;
    cursor: pointer;
    transition: all 0.3s ease;
    font-size: 20px;
  }

  /*Стили для кнопок по инжинерным системам*/

  .btn_addwear_inz_wear {
    display: flex;
    justify-content: center;
    flex-direction: row;
    width: 100%;
  }
  
  /* Медиа-запросы для адаптации стилей под различные устройства */
  @media only screen and (max-width: 768px) {
    .btn_addwear_wear {
      width: 50%;
    }
  
    .wear-input-container-addwear {
      width: 90%;
    }
  
    .wear-input-addwear {
      width: 100%;
    }
  
    .wear-table {
      width: 90%;
    }
  
    .btn_addwear_hold_wear {
      width: 50%;
    }
  }
  