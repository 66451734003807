.TypeHouse {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    height: 80vh; /* Размер высоты для центрирования по вертикали */
  }
  
   .TypeHouse-select-wear {
    height: 20%;
    width: 50%; /* Ширина выпадающего списка */
    padding: 10px; /* Внутренние отступы */
    font-size: 2em; /* Размер шрифта */
    border-radius: 5px; /* Скругление углов */
    outline: none; /* Убираем контур фокуса */
  }

  .TypeHouse-option-wear {
    color: black;
    padding: 5px; /* Уменьшаем внутренние отступы */
    font-weight: bold; /* Жирный текст */
    font-size: 70%; /* Уменьшаем размер текста */
    overflow-wrap: break-word; /* Переносим текст на следующую строку, если он не помещается */
  }
  
  
  