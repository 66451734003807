.Footer{
    background: linear-gradient(to bottom, #3D5D99, #304578);

    &__inner {
        padding: 25px 0;
    }

    &__content {
        display: flex;
        justify-content: space-between;
        color: #fff;
        border-bottom: 1px solid rgba(255, 255, 255, 0.15);
        padding-bottom: 30px;
        margin-bottom: 25px;
    }

    &__content-item__title {
        font-weight: 700;
        font-size: 18px;
        line-height: 22px;
        margin-bottom: 30px;
    }
    &__content-item__list-item{
        font-weight: 500;
        font-size: 16px;
        line-height: 20px;  
        &+.Footer__content-item__list-item{
            margin-top: 10px;
        }
    }
    &__copy-text{
        font-size: 14px;
        line-height: 14px;
        color: rgba(255, 255, 255, 0.5);
    }
    &__logo-img{
        width: 100%;
    }
    &__logo-box{
        width: 116px;
        height: 103px;
    }
}