.HydrologistButton {
  max-width: 200px;
  .button-three {
    text-align: center;
    cursor: pointer;
    font-size: 24px;
  }

    .button-three {
      position: relative;
      background-color: RoyalBlue;
      box-shadow: 0 0 0.8em 0 rgba(65, 105, 225, .8);
      border: none;
      padding: 12px;
      width: 200px;
      color: #fff;
      text-align: center;
      -webkit-transition-duration: 0.4s;
      /* Safari */
      transition-duration: 0.4s;
      text-decoration: none;
      overflow: hidden;
    }
  
    .button-three:hover {
      background: #fff;
      box-shadow: 0px 2px 10px 5px #97B1BF;
      color: #000;
    }
  
    .button-three:after {
      content: "";
      background: #6d93da;
      display: block;
      position: absolute;
      padding-top: 300%;
      padding-left: 350%;
      margin-left: -20px !important;
      margin-top: -120%;
      opacity: 0;
      transition: all 0.8s
    }
  
    .button-three:active:after {
      padding: 0;
      margin: 0;
      opacity: 1;
      transition: 0s
    }
}