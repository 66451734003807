$primary-color: #053364; // Основной цвет для кнопок и других элементов
$hover-color: #0056b3; // Цвет при наведении

.container {
  padding: 2rem;
  text-align: center;
  margin-top: 10vh;
}

.buttonContainer {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
  justify-content: center;
}



button {
  background-color: $primary-color;
  color: black;
  padding: 5px 10px;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  transition: all 0.3s ease;
  font-size: 1rem;

  &:hover {
    background-color: $hover-color;
    transform: scale(1.05);
  }

  &:active {
    background-color: darken($hover-color, 10%);
    transform: scale(0.95);
  }
}

.mainBtn {
  background-color: $primary-color;
  color: white;
  padding: 5px 10px;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  transition: all 0.3s ease;
  font-size: 1rem;

  &:hover {
    background-color: $hover-color;
    transform: scale(1.05);
  }

  &:active {
    background-color: darken($hover-color, 10%);
    transform: scale(0.95);
  }
}

.contentContainer {
  margin-top: 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
}
