.tcontrol {
  .container {
    position: relative;
  }

  &__settings {
    position: fixed;
    bottom: 5%;
    right: 1%;
  }

  &__settings-btn {
    background-color: transparent;
    transition: all .3s;
    border: none;
    @extend %main__animation;
    border-radius: 50%;
    padding: 5px;

    &:hover {
      transform: translateY(-5px);
    }
  }

  &__form-row {
    // display: flex;
    flex-wrap: wrap;

    div {
      padding-top: 10px;
      // display: flex;
    }
  }

  &__form &__form-row &__input-data {
    // width: 100%; 
    height: 40px;
    margin: 0 20px;
    margin-bottom: 30px;
    position: relative;
    max-width: 250px;
  }

  &__form &__form-row &__input-data--register {
    margin-bottom: 0;
  }

  &__input-data input,
  .textarea textarea {
    display: block;
    width: 100%;
    height: 100%;
    background-color: transparent;
    outline: none;
    border: none;
    font-size: 17px;
    border-bottom: 2px solid rgba(0, 0, 0, 0.12);
  }

  &__input-data input:disabled~label {
    color: #a5a7a8;
  }



  &__input-data input:focus~label,
  .textarea textarea:focus~label,
  &__input-data input:valid~label,
  .textarea textarea:valid~label {
    transform: translateY(-20px);
    font-size: 14px;
    @extend %main__animation-text;
  }

  .textarea textarea {
    resize: none;
    padding-top: 10px;
  }

  &__input-data label {
    position: absolute;
    pointer-events: none;
    bottom: 10px;
    font-size: 16px;
    transition: all 0.3s ease;
  }

  .textarea label {
    width: 100%;
    bottom: 40px;
    background: #fff;
  }

  &__input-data &__underline {
    position: absolute;
    bottom: 0;
    height: 2px;
    width: 100%;
    transform: translateY(-1.5px);
  }

  &__input-data &__underline:before {
    position: absolute;
    content: "";
    height: 2px;
    width: 100%;

    @extend %main__animation;
    transform: scaleX(0);
    transform-origin: center;
    transition: transform 0.3s ease;
  }

  &__input-data input:focus~&__underline:before,
  &__input-data input:valid~&__underline:before,
  .textarea textarea:focus~&__underline:before,
  .textarea textarea:valid~&__underline:before {
    transform: scale(1);
  }



  @media (max-width: 700px) {
    .text {
      font-size: 30px;
    }

    form {
      padding: 10px 0 0 0;
    }

    form &__form-row {
      display: block;
    }

    form &__form-row &__input-data {
      margin: 35px 0 !important;
    }

    &__submit-btn &__input-data {
      width: 40% !important;
    }
  }

  @mixin createButton($width, $height, $radius, $gradientFirst, $gradientThird, $shadowColor) {
    width: $width;
    height: $height;
    border-radius: $radius;
    background-image: linear-gradient(135deg, $gradientFirst 0%, $gradientThird 100%);
    box-shadow: 0 20px 30px -6px $shadowColor;
    outline: none;
    border: none;
    cursor: pointer;
    font-size: 22px;
    color: white;
    @extend %main__animation;
  }

  &__btn {

    transition: all .3s ease-in-out;

    & {
      @include createButton(200px, 60px, 50px, #6d93da, #3d5d99, rgba(30, 161, 255, 0.5));
    }

    &:hover {
      transform: translatey(10px);
      box-shadow: none;
    }

    &:active {
      opacity: 0.5;
    }

  }

  &__register-form {
    margin-top: 150px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &__register-btn {
    margin-top: 20px;
  }

  &__btn-input {
    @extend %main__animation;
    border: none;
  }

  &__nav {
    display: flex;
    white-space: nowrap;
    background: transparent;
    padding: var(--underline-height);
    margin: 2em 0;
    margin-top: 100px;
    // justify-content: center;
    position: relative;

    .tcontrol__nav-underline {
      border-radius: 100% 100% 0 0;
      background: green;
    }

    // a{
    //   margin-left: 60px;
    // }
  }

  &__nav-underline {
    display: block;
    position: absolute;
    z-index: 0;
    bottom: 0;
    left: 0;
    height: var(--underline-height);
    width: 200px;
    background: black;
    pointer-events: none;
    mix-blend-mode: multiply;
    transition: all .5s;

    &.active1 {
      background: #3d5d99;
    }

    &.active2 {
      background: yellow;
    }

    &.active3 {
      background: rgb(197, 3, 3);
    }

    &.active4 {
      background: magenta;
    }
  }

  &__nav-link {
    display: inline-block;
    z-index: 10;
    min-width: 200px;
    padding: 1em 0;
    text-align: center;
    color: #a5a7a8;
    font-size: 18px;
    font-weight: bold;
    cursor: pointer;

    &.active {
      color: #000;
    }
  }

  &__title {
    margin-top: 60px;
    font-size: 30px;
    margin-left: 15px;
    text-align: center;
    margin-bottom: 50px;
  }

  &__title-box {
    position: relative;
  }

  &__title-box span {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    width: 20%;
    height: 4px;
    bottom: -6px;
    background-color: #3d5d99;
    border-radius: 0 15px 15px 0;
  }

  &__table {

    th,
    td {
      background-color: $bg-color;
      border: 1px solid $text-color;
      text-align: center;
      line-height: 20px;
    }
  }

  &__form--dynamic {
    margin-top: 50px;
  }

  &__select,
  &__input {
    max-width: 250px;
  }

  &__dynamicValues-btn {
    margin-top: 20px;
    display: flex;
    align-items: center;

    span {
      margin-left: 20px;
    }
  }

  &__title-box--closeSmena span {
    background-color: magenta;
  }

  &__title-box--equipment span {
    background-color: yellow;
  }

  &__main-btn__group {
    left: 50%;
    transform: translateX(-50%);
    margin-bottom: 30px;

    button {
      font-size: 20px;
      background-color: #3d5d99;
      border-color: #3a578d;

      &:hover {
        background-color: #4064a8;
        border-color: #4264a3;
      }
    }

    .active {
      // color: $accent-color;
      background-color: #2c4571;
    }
  }

  &__closeSmena {
    button {
      margin: 0 auto;
    }
  }

  &__btn-box {
    display: flex;
    justify-content: center;
  }

  &__dynamicValues-title {
    margin-top: 30px;
    font-weight: bold;
  }



}

:root {
  --underline-height: .5em;
  --transition-duration: .5s;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  /* display: none; <- Crashes Chrome on hover */
  -webkit-appearance: none;
  margin: 0;
  /* <-- Apparently some margin are still there even though it's hidden */
}

input[type=number] {
  -moz-appearance: textfield;
  /* Firefox */
}

.col {
  display: flex;
  flex-direction: column;

  .form-label {
    margin-top: auto;
  }

  .tcontrol__input {
    margin-top: auto;
  }
}

// .modal{
//   width: 50%;
//   height: 50%;
//   background-color: #fff;
//   border-radius: 7px;

// }