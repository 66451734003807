/* Общие стили для контейнера */
.address-container {
  max-width: 800px; /* Увеличиваем максимальную ширину */
  margin-top: 60px;
  padding: 20px 80px;
  background-color: #f9f9fb;
  border-radius: 12px;
  box-shadow: 0 6px 18px rgba(0, 0, 0, 0.496);
  text-align: center;
  font-family: 'Arial', sans-serif;
  opacity: 0;
  transform: translateY(20px);
  animation: fadeIn 0.6s ease-out forwards;
}

/* Анимация плавного появления */
@keyframes fadeIn {
  to {
      opacity: 1;
      transform: translateY(0);
  }
}

/* Стили заголовка */
.address-container h2 {
  margin-bottom: 20px;
  color: #333;
  font-size: 26px;
  font-weight: bold;
  opacity: 0;
  transform: translateY(-10px);
  animation: fadeInTitle 0.8s ease-out forwards 0.2s;
}

/* Анимация заголовка */
@keyframes fadeInTitle {
  to {
      opacity: 1;
      transform: translateY(0);
  }
}

/* Стили для поля ввода адреса */
.address-search-input {
  width: 100%; /* Занимает всю ширину контейнера */
  max-width: 400px; /* Увеличиваем максимальную ширину поля ввода */
  padding: 15px; /* Увеличиваем отступы */
  margin-bottom: 20px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 18px; /* Увеличиваем размер шрифта */
  transition: border-color 0.3s;
}

.address-search-input:focus {
  border-color: #007bff; /* Изменение цвета рамки при фокусе */
  outline: none; /* Убираем стандартное обрамление */
}

/* Стили для списка адресов */
.address-list {
  list-style: none;
  padding: 0;
  margin: 0;
  max-height: 300px; /* Ограничиваем максимальную высоту */
  overflow-y: auto; /* Добавляем прокрутку по вертикали */
  opacity: 0;
  animation: fadeInList 1s ease-out forwards 0.4s;
}

/* Анимация для списка адресов */
@keyframes fadeInList {
  to {
      opacity: 1;
  }
}

.address-item {
  padding: 10px; /* Увеличиваем отступы */
  margin-bottom: 12px;
  background-color: #007bff;
  color: #fff;
  border-radius: 8px;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.2s ease, box-shadow 0.2s ease;
  font-size: 20px; /* Увеличиваем размер шрифта */
  font-weight: 400;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.address-item:hover {
  background-color: #0056b3;
  transform: translateY(-3px); /* Легкий эффект подъема при наведении */
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2); /* Увеличенная тень */
}

/* Стили для отображения результата */
.result {
  margin-top: 10px;
  padding: 5px;
  background-color: #e9f7f9;
  border-left: 4px solid #007bff;
  border-radius: 8px;
  text-align: left;
  color: #333;
  opacity: 0;
  transform: translateY(10px);
  animation: fadeInResult 1s ease-out forwards 0.5s;
}

/* Анимация для блока с результатом */
@keyframes fadeInResult {
  to {
      opacity: 1;
      transform: translateY(0);
  }
}

.result p {
  margin: 5px 0;
  font-size: 20px; /* Увеличиваем размер шрифта */
}

.result strong {
  font-weight: bold;
  color: #007bff;
}

/* Стили для сообщения об отсутствии адресов */
.no-addresses-message {
  font-size: 20px; /* Увеличиваем размер шрифта */
  color: #ff4d4f;
  padding: 20px;
  background-color: #fff5f5;
  border: 1px solid #ff4d4f;
  border-radius: 8px;
  margin-top: 20px;
}

/* Стили для сообщения о загрузке */
.loading-message {
  font-size: 20px; /* Увеличиваем размер шрифта */
  color: #007bff;
  padding: 20px;
  background-color: #f0f8ff;
  border-radius: 8px;
  margin-top: 20px;
}
