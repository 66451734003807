.Ads2{
    &__container{
        margin-top: 30px;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
    }

    &__ContForPhoto{
        margin-top: 20px;
        margin-bottom: 30px;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: row;
    }

    &__PhotoStart{
        margin-right: 50px;
        width: 700px;
        height: 500px;
    }

    &__PhotoEnd{
        width: 700px;
        height: 500px;
    }

    &__TtleStartPhoto{
        margin-right: 450px;
    }
}