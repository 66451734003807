.main-content_option {
    margin-top: 40px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .form-group {
    margin-bottom: 20px;
    text-align: center;
  }
  
  .label-field-data-wear {
    display: block;
    margin-bottom: 8px;
    font-size: 16px;
    font-weight: bold; /* Текст в жирном стиле */
  }
  
  .select-field-data-wear {
    width: 600px;
    padding: 20px; /* Увеличиваем внутренний отступ */
    margin: 15px 10px; /* Увеличиваем внешний отступ снизу и оставляем отступ слева/справа как раньше */
    border: 1px solid #777777; /* Добавляем серую обводку */
    border-radius: 5px;
    box-sizing: border-box;
  }
  
  .option-field-data-wear {
    font-size: 16px;
    text-align: center; /* Центрируем текст внутри каждого элемента списка */
  }