.NewsPage {
    &__Title {
        margin-top: 150px;
        text-align: center;
        font-size: 48px;
        font-weight: bold;
        color: #333;
        font-family: Arial, sans-serif;
    }
    &__video-container{
        margin-top: 30px;
        margin-bottom: 100px;
        display: flex;
        justify-content: center;

    }

    &__V1 {
        width: 80%;
        max-width: 800px;
        height: auto;
      }
}