
  .btn-osn-wear {
    background-color: #1f487e;
    color: white;
    border: none;
    padding: 15px 25px; /* Увеличение отступов */
    margin: 10px; /* Увеличение отступов */
    border-radius: 8px;
    cursor: pointer;
    transition: all 0.3s ease;
    font-size: 18px; /* Увеличение размера текста */
  }
  
  .btn-osn-wear:hover {
    opacity: 0.8;
  }
  
  .btn-osn-wear:active {
    transform: translateY(2px);
  }


 
  
  