.WPSInfo__link {
  text-decoration: none;
}

.card {
  background-color: RoyalBlue;
  box-shadow: 0 4px 8px 0 rgba(65, 105, 225, 0.8);
  border: none;
  padding: 20px;
  color: #fff;
  text-align: center;
  transition: all 0.3s ease;
  border-radius: 10px;
  margin-right: 20px;
  margin-bottom: 20px;
  display: inline-block;
  cursor: pointer;
  font-size: 18px;
}

.card:hover {
  background-color: #fff;
  box-shadow: 0 8px 16px 0 rgba(65, 105, 225, 0.8);
  color: #000;
}