.main-content {
  margin-top: 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.data_mane {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 20px;
}

.data_input {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  max-width: 1000px; /* Ограничиваем максимальную ширину, чтобы избежать слишком широких полей */
}

.input-field-data-wear {
  width: calc(50% - 20px); /* Распределяем поля в две колонки с отступом между ними */
  padding: 20px; /* Увеличиваем внутренний отступ */
  margin: 15px 10px; /* Увеличиваем внешний отступ снизу и оставляем отступ слева/справа как раньше */
  border: 1px solid #777777; /* Добавляем серую обводку */
  border-radius: 5px;
  box-sizing: border-box;
}

.input-field-data-wear-AddressSuggestions{
  width: 187%; /* Распределяем поля в две колонки с отступом между ними */
  padding: 20px; /* Увеличиваем внутренний отступ */
  margin: 15px 10px; /* Увеличиваем внешний отступ снизу и оставляем отступ слева/справа как раньше */
  border: 1px solid #777777; /* Добавляем серую обводку */
  border-radius: 5px;
  box-sizing: border-box;
}

.div-text-span-wear {
  margin-left: 10%; /* Задайте необходимый отступ слева */
}

.text-span-wear {
  font-size: 1.2em;
  position: relative; /* Устанавливаем позицию, чтобы псевдоэлемент был относительно этого элемента */
}

.text-span-wear::after {
  content: ''; /* Добавляем контент (в данном случае, линию) */
  display: block; /* Превращаем контент в блочный элемент */
  width: 100%; /* Ширина линии будет равна ширине родительского элемента */
  height: 2px; /* Толщина линии */
  background-color: #000; /* Цвет линии */
  position: absolute; /* Абсолютное позиционирование относительно родительского элемента */
  bottom: 0; /* Располагаем линию внизу */
  left: 0; /* Располагаем линию слева */
}


.group-checkbox {
  display: flex;
  flex-wrap: wrap;
  justify-content: center; /* Центрируем чекбоксы по горизонтали */
  margin:15px;
}

.label-check-data-wear {
  flex-basis: 20%; /* Ширина каждого чекбокса - 20% от контейнера (5 чекбоксов в строке) */
  padding: 10px;
  font-weight: bold; /* Текст в жирном стиле */
  box-sizing: border-box;
}

.input-check-data-wear {
  flex-basis: 20%; /* Ширина каждого чекбокса - 20% от контейнера (5 чекбоксов в строке) */
  margin: 10px;
  box-sizing: border-box;

}
