.no-scroll {
    overflow: hidden;
}

.Ads {
    margin-top: 140px;
    margin-bottom: 300px;
    max-height: 100%;
    min-height: calc(100vh - 722px);

    &__button {
        display: flex;
        justify-content: center;
        font-family: 'Montserrat';
        font-style: normal;
        font-size: 20px;
        max-width: 100%;
    }

    &__bimba {
        @extend %btn;
        margin-right: 90px;
        text-align: center;
    }

    &__Mappp {
        position: relative;
        margin-top: 50px;

    }

    &__sel1 {
        border-color: white;
        border-width: 1px;
        border-radius: 5px;
        width: 250px;
        text-align: center;
        padding-bottom: 10px;
        padding-top: 10px;
    }

    &__Mappp-legend {
        background-color: $bg-color;
        position: absolute;
        top: 190px;
        right: 0.1px;
        padding-left: 10px;
        padding-right: 10px;
        padding-top: 10px;
        padding-bottom: 30px;
        border-color: black;
        border: solid;
        border-radius: 5px;
    }

    &__Mappp-legend1 {
        background-color: $bg-color;
        position: absolute;
        top: 1570px;
        right: 0.1px;
        padding-left: 10px;
        padding-right: 10px;
        padding-top: 10px;
        padding-bottom: 30px;
        border-color: black;
        border: solid;
        border-radius: 5px;
    }

    &__Mappp-legend-mark {
        margin-top: 20px;
    }

    &__Mappp-legend-mark-red {
        color: red;
    }

    &__Mappp-legend-mark-yelow {
        color: orange;
    }

    &__Mappp-legend-mark-blue {
        color: $default-color;
    }

    &__Mappp-legend-mark-green {
        color: green;
    }

    &__dadata {
        padding-bottom: 10px;
        padding-right: 10px;


    }

    &__sel {
        border-color: white;
        border-width: 1px;
        border-radius: 5px;
        padding-right: 43px;
        padding-left: 43px;
        text-align: center;
        padding-bottom: 10px;
        padding-top: 10px;
    }

    &__ForBorderColor{
        border-color: black;
    }

    &__save {
        @extend %btn;
        font-family: 'Montserrat';
        font-style: normal;
        font-size: 17px;
        margin-left: 70px;

    }

    &__map {
        @extend %btn;
        font-family: 'Montserrat';
        font-style: normal;
        font-size: 20px;
        margin-top: 100px;

    }

    &__PrCont {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    &__text {
        margin-top: 30px;
        display: grid;
        grid-template-columns: repeat(4, 200px);
        grid-template-rows: auto;
        grid-row-gap: 20px;
        grid-column-gap: 120px;
        max-width: 2000px;
    }

    &__TopMargStat{
        margin-top: 100px;
        margin-left: -210px;
    }

    &__RabModalCont {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        margin-top: 50px;
        margin-bottom: 50px;
    }

    &__ModalRabPhoto {
        width: 700px;
        height: 500px;
    }

    &__text1 {
        margin-top: 70px;
        padding-left: 120px;
        padding-right: 120px;
        margin-right: 70px;
        display: grid;
        grid-template-columns: repeat(4, 250px);
        grid-column-gap: 50px;
        grid-template-rows: 100px;

    }

    &__buttonMapCoordinate {
        @extend %btn;
        padding-left: 10px;
        padding-right: 10px;
        padding-top: 10px;
        padding-bottom: 10px;
        height: 60px;
        margin-top: 30px;
    }

    &__MapSlice {
        position: absolute;
        right: 50px;
        top: 900px;
    }

    &__map-button {
        @extend %btn;
        position: absolute;
        right: 50px;
        bottom: 50px;
    }

    &__VAllMap {
        position: absolute;
        right: 300px;
        bottom: 50px;
    }

    &__map-button1 {
        @extend %btn;
        position: absolute;
        right: 50px;
        top: 2000px;
    }

    &__InJobStatus {
        display: grid;
        grid-template-columns: repeat(4, 250px);
        grid-template-rows: 80px;
        grid-column-gap: 80px;
    }

    &__WidhtMap {
        width: 100%;
    }

    &__InJobStatusBtn {
        @extend %btn;
        margin-top: 80px;
        margin-left: 120px;
    }

    &__CentralContForDTable {
        margin-top: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
    }

    &__InJobStatusBtn1 {
        @extend %btn;
        margin-top: 80px;
    }

    &__ZagolovokTable {
        text-align: center;
        margin-top: 35px;
    }

    &__block {
        display: block;
    }

    &__file {
        display: none;
    }

    &__upload-button {
        @extend %btn;
        text-align: center;
        margin-left: 50px;
        margin-top: 30px;
        width: 200px;
        height: 60px;
    }

    &__BlockIspZayvka {
        margin-top: 50px;
        margin-left: 70px;
    }

    &__tableById4 {
        margin-top: 20px;
        max-width: 800px;
        margin-left: auto;
        margin-right: auto;
    }

    &__BlocStartEndPhoto {
        margin-top: 40px;
    }

    &__PhotoStart {
        margin-left: auto;
        margin-right: auto;
        max-width: 1000px;
        margin-bottom: 40px;
    }

    &__PhotoEnd {
        margin-left: auto;
        margin-right: auto;
        max-width: 1000px;
        margin-bottom: 40px;
    }

    &__upload-button2 {
        @extend %btn;
        text-align: center;
        margin-left: 0px;
        margin-top: 10px;
        width: 220px;
        height: 60px;
    }

    &__contanerProgr {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
    }

    &__ForCenterTable {
        margin-top: 30px;
        display: flex;
        justify-content: center;
        align-items: center;

    }

    &__NonActvZ {
        @extend %btn;
        margin-right: 50px;
    }

    &__EdtFragBtn1 {
        @extend %btn;
        width: 180px;
        height: 60px;
    }

    &__EdtFragBtn {
        @extend %btn;
        height: 60px;
    }


    &__ActvZ {
        @extend %btn;
        margin-right: 50px;
    }

    &__ActvZ1 {
        @extend %btn;
    }

    &__upload-button3 {
        @extend %btn;
        text-align: center;
        margin-top: 10px;
        width: 220px;
        height: 60px;
    }

    &__upload-button1 {
        @extend %btn;
        text-align: center;
        margin-left: 120px;
        width: 200px;
        height: 60px;
    }

    &__uploadPhotobnt {
        @extend %btn;
        margin-top: 45px;
        height: 50px;
        text-align: center;
        margin-right: 50px;
    }

    &__confrmModalBtn {
        @extend %btn;
        margin-top: 45px;
        height: 50px;
        text-align: center;

    }

    &__DateSearchProgr {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 50px;
    }

    &__CentrContBtnSProgr {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 30px;
    }

    &__selected-file1 {
        margin-top: 30px;
        margin-left: 120px;
        font-weight: bold;
    }

    &__selected-fileModal {
        position: absolute;
        bottom: 10%;
        left: 5%;
    }

    &__confrmUp {
        @extend %btn;
        margin-left: 120px;
        margin-top: 20px;
    }



    &__ForMarg {
        margin-top: 70px;
    }

    &__selected-file {
        margin-top: 30px;
        font-weight: bold;
    }

    &__PhotoCont {
        margin-top: 50px;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    &__photoObj {
        max-width: 1300px;
        max-height: 100%;
    }

    &__EdtPerf {
        color: #000;
        background-color: #fff;
        width: 650px;
        border-radius: 20px;
        height: 400px;
        position: fixed;
        top: 20%;
        left: 30%;
        z-index: 9999;
    }

    &__ForMargGraph {
        margin-top: 15px;
    }

    &__SearchModalZayva {
        color: #000;
        background-color: #fff;
        width: 1400px;
        border-radius: 20px;
        height: 700px;
        position: fixed;
        top: 10%;
        left: 13%;
        z-index: 9999;
    }

    &__SearchModalZayva1 {
        color: #000;
        background-color: #fff;
        width: 1500px;
        border-radius: 20px;
        height: 800px;
        position: fixed;
        top: 10%;
        left: 10%;
        z-index: 9999;
    }

    &__SelSearch1ModalSearch {
        padding: 13px 75px;
        font-size: 16px;
        border: 1px solid #000;
        border-radius: 5px;
        background-color: #fff;
        outline: none;
    }

    &__ForFontSze {
        font-size: 13.5px;
    }

    &__execlBtn {
        @extend %btn;
        margin-top: 20px;
        margin-left: 30px;
    }

    &__execlBtn1 {
        @extend %btn;
        margin-right: 30px;

    }

    &__EdtModalZayvaMap {
        color: #000;
        background-color: #f0f0f0;
        filter: brightness(0.9);
        width: 500px;
        border-radius: 20px;
        height: 200px;
        position: fixed;
        top: 35%;
        left: 35%;
        z-index: 999;

    }

    &__SmallModalCont {
        display: flex;
        flex-direction: row;
        justify-content: center;
        font-size: 17px;
    }

    &__ForSmallModalW {
        margin-top: 30px;
    }

    &__ForSmallModalWdth {
        width: 170px;
    }

    &__SmallTtle {
        text-align: center;
        font-size: 20px;
    }

    &__ContForTtle {
        margin-top: 20px;
    }

    &__BtnForDispIspZayvka {
        cursor: pointer;
        color: #fff;
    }

    &__btnSearchModal1 {
        @extend %btn;
        width: 500px;
        margin-left: 30px;
    }

    &__btnSearchModalCont {
        margin-top: 40px;
        display: flex;
        justify-content: center;
    }

    &__ForChangeFragment {
        cursor: pointer;
        border: none;
    }

    &__btnSearchModal {
        @extend %btn;
    }

    &__searchCont {
        margin-top: 30px;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    &__EdtTtlePerd {
        margin-top: 20px;
        text-align: center;
    }

    &__AddPerfBtn {
        @extend %btn;
        margin-right: 25px;
    }

    &__DelPerfBtn {
        @extend %btn;
    }

    &__confrAddPerf {
        margin-top: 20px;
        @extend %btn;
    }

    &__CloseModalBtn {
        cursor: pointer;
        color: red;
        position: absolute;
        top: 0%;
        right: 0%;
        border: none;
        font-size: 25px;

    }

    &__DopStyle {
        border-color: #000;
    }

    &__DopForLabel {
        margin-left: -60px;
    }

    &__DopForLabel1 {
        margin-right: 100px;
    }

    &__DopForLabel2 {
        margin-right: 50px;
    }

    &__DopAdrStyle {
        margin-right: 30px;
        border-color: #000;
    }

    &__AddCont {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
    }

    &__AddCont1 {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: row;
    }

    &__DopAdrStyle1 {
        margin-right: 100px;
    }

    &__ModalContainer {
        margin-top: 50px;
        display: flex;
        justify-content: center;
    }

    &__tableByIdModal {
        overflow-y: scroll;
        max-height: 400px;
        margin-top: 30px;
        margin-left: 30px;
        margin-right: 30px;
    }

    &__ForFlexTableModalSearch {
        display: flex;
        justify-content: center;

    }

    &__ForFlexRucFltrs {
        display: flex;
        flex-direction: row;
    }

    &__DopForLabel3 {
        margin-top: 11px;
        margin-left: 80px;
    }

    &__ModalBg {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: 100;
        background-color: rgba(0, 0, 0, 0.5);
    }

    &__RabModal {
        color: #000;
        background-color: #f0f0f0;
        filter: brightness(0.9);
        width: 100%;
        height: 100%;
        position: fixed;
        top: 0;
        left: 0;
        z-index: 999;
        overflow-y: scroll;
    }

    &__ForWaitBtn {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: row;

    }

    &__ChangePhoto {
        margin-top: 80px;
    }

    &__ChangeBtnPhoto {
        @extend %btn;
    }

    &__ForMargDBtn {
        margin-right: 80px;
    }

    &__ForMargDBtn1 {
        margin-right: 20px;
    }

    &__ChangeMontModal {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: row;
    }

    &__SelSearch {
        width: 250px;
        padding: 13px 75px;
        font-size: 16px;
        border: 1px solid #000;
        border-radius: 5px;
        background-color: #fff;
        outline: none;
    }

    &__ClearFltres {
        margin-left: 70px;
        margin-top: 30px;
        @extend %btn;
    }

    &__BtnSearch1Modal {
        @extend %btn;
        margin-top: 10px;
        margin-right: 20px;
        width: 200px;
    }

    &__ChangeBtnPhoto1 {
        margin-left: 70px;
        @extend %btn;
    }

    &__text-input__getZ {
        @extend %btn;
        margin-top: 50px;
        margin-left: 120px;
    }

    &__ContactBlock {
        margin-top: 40px;
        display: flex;
        justify-content: center;
    }

    &__ForMarg {
        margin-right: 110px;
    }

    &__TitleContactBlock {
        margin-top: 50px;
        text-align: center;
    }

    &__tableById {

        max-width: 1750px;
    }

    &__tableById1 {
        margin-top: 30px;
        margin-left: 10px;
        margin-right: 10px;
    }

    &__tableById2 {
        margin-top: 10px;
        margin-bottom: 20px;
        margin-left: 10px;
        margin-right: 10px;
        width: 100%;
        height: 300px;
        overflow-x: scroll;
    }

    &__tableById3 {
        height: 300px;
        overflow-y: scroll;
    }

    &__title2 {
        margin-top: 50px;
        margin-left: 70px;
        text-align: left;
    }

    ::-webkit-scrollbar {
        width: 6px;
    }

    ::-webkit-scrollbar-track {
        box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    }

    ::-webkit-scrollbar-thumb {
        box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    }

    &__Graph {
        margin-top: 50px;
    }

    &__explanation {
        // position: absolute;
        // left: 460px;
        // top: 700px;
    }

    &__explanation1 {
        position: absolute;
        left: 740px;
        top: 447px;
    }

    &__ForWdht {
        width: 280px;
    }

    &__ForWdht1 {
        width: 250px;
    }

    &__tooltip {
        position: absolute;
        bottom: -20px;
        left: 0;
        background-color: #f1f1f1;
        color: #333;
        padding: 5px;
        border-radius: 4px;
    }

    &__textZ {
        border-color: white;
        border-width: 1px;
        border-radius: 5px;
        padding-left: 30px;
        padding-right: 30px;
        padding-top: 30px;
        padding-bottom: 30px;
    }


    &__text-input__inline {
        border-color: white;
        border-width: 1px;
        border-radius: 5px;
        padding-top: 7px;
        padding-bottom: 10px;
        padding-right: 10px;

    }

    &__text-input__inline1 {
        border-color: white;
        border-width: 1px;
        border-radius: 5px;
        padding-top: 7px;
        padding-bottom: 10px;
        width: 210px;
        text-align: center;

    }

    &__text-input__inline2 {
        border-color: white;
        border-width: 1px;
        border-radius: 5px;
        padding-top: 7px;
        padding-bottom: 10px;
        width: 210px;


    }

    // &__ForMarginPriem{
    //     margin-top: 30px;
    // }
    &__text-input {
        width: 215px;
    }

    &__text-input1 {
        width: 300px;
    }

    &__text-input2 {
        width: 250px;
    }

    &__Mappp-search {
        position: absolute;
        top: 27px;
        left: 310px;
        padding-top: 9px;
        padding-bottom: 9px;
        padding-right: 20px;
        padding-left: 20px;

    }

    &__Mappp-search1 {
        position: absolute;
        top: 1542px;
        left: 310px;
        padding-top: 9px;
        padding-bottom: 9px;
        padding-right: 20px;
        padding-left: 20px;

    }

    &__Mappp-btnSearch {
        @extend %btn;
        position: absolute;
        top: 20px;
        left: 580px;
    }

    &__Mappp-btnSearch1 {
        @extend %btn;
        position: absolute;
        top: 1535px;
        left: 580px;
    }

    &__selectSearch1 {
        position: absolute;
        top: 10px;
        left: 10px;
        font-weight: bold;
    }

    &__selectSearch3 {
        position: absolute;
        top: 1525px;
        left: 10px;
        font-weight: bold;
    }

    &__center-contRab {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: row;

    }


    &__Mappp-search-dadata {
        position: absolute;
        top: 0px;
        left: 250px;
        padding-top: 13px;
        padding-bottom: 13px;
        padding-right: 20px;
        padding-left: 20px;
    }

    &__MapContPer {
        position: absolute;
        top: 5px;
        left: 250px;
        display: flex;
        flex-direction: row;
    }

    &__loading-overlay {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: rgba(0, 0, 0, 0.7);
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 1000;
        overflow: hidden;
    }

    &__loading-wheel {
        border: 10px solid #f3f3f3;
        border-top: 10px solid #3498db;
        border-radius: 50%;
        width: 50px;
        height: 50px;
        animation: spin 1s linear infinite;
        margin: 20px auto;
    }

    @keyframes spin {
        0% {
            transform: rotate(0deg);
        }

        100% {
            transform: rotate(360deg);
        }
    }

    &__ForMargMapDsp1 {
        margin-left: 50px;
    }

    &__ForMargDvForDspMap {
        margin-left: 30px;
        display: flex;
    }

    &__ForMargMapDsp {
        margin-left: 10px;
    }

    &__Mappp-search-dadata1 {
        position: absolute;
        top: 1530px;
        left: 310px;
        padding-top: 13px;
        padding-bottom: 13px;
        padding-right: 20px;
        padding-left: 20px;
    }

    &__Mapppp {
        margin-top: 50px;
        position: relative;

    }

    &__title {
        margin-top: 30px;
        text-align: center;
    }

    &__title1 {
        margin-top: 20px;
        margin-bottom: 20px;
        text-align: center;
    }

    &__ForBtnInMapChange {
        position: absolute;
        left: 35%;
        top: 90%;
    }

    &__BtnOnChangeMap {
        @extend %btn;
        margin-left: 40px;
    }

    &__Mappp-search-data {
        position: absolute;
        top: 25px;
        left: 340px;
        padding-top: 10px;
        padding-bottom: 10px;
        padding-right: 30px;
        padding-left: 30px;
    }

    &__Mappp-search-data1 {
        position: absolute;
        top: 1540px;
        left: 340px;
        padding-top: 10px;
        padding-bottom: 10px;
        padding-right: 30px;
        padding-left: 30px;
    }

    &__ThisBtnForGraph {
        @extend %btn;
    }

    &__ForMargGraphStat{
        margin-left: 320px;
        margin-top: -143px;
    
    }

    

    

    &__ForMargGraphStat1{
        margin-left: 300px;
        margin-top: -70px;
        margin-bottom: 20px;
        white-space: nowrap;
        height: 50px;
    }

    &__Crug {
        margin-left: auto;
        margin-right: auto;
        width: 500px;
    }

    &__ForFlex {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    &__BtnForGraph {
        margin-top: 30px;
        display: grid;
        grid-template-columns: repeat(6, 220px);
        grid-column-gap: 50px;

    }

    &__GraphInput {
        margin-bottom: 20px;
        margin-left: 70px;
    }
    
    &__GraphInput1 {
        display: flex;
        margin-bottom: 20px;
        margin-left: 70px;
    }

    &__center-container {
        position: fixed;
        top: 20%;
        left: 50%;
        transform: translate(-50%, -50%);
    }

    &__center-container1 {
        position: fixed;
        left: 45%;
        transform: translate(-50%, -50%);
    }

    &__center-container2 {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 50px;
    }

    &__contCentrSave {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    &__tableOleg {
        border-collapse: collapse;
        border: 1px solid #000;
        table-layout: fixed;

        th,
        td {
            padding: 10px 7px;
        }

        th {
            font-weight: bold;
            border: 1px solid #000;
            text-align: center;
            word-wrap: break-word;
            font-size: 15px;
        }

        td {
            font-weight: normal;
            border: 1px solid #000;
            text-align: center;
            word-wrap: break-word;
            font-size: 18px;
        }
    }

    &__ContanerRabBtn {
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 20px;
        font-family: 'Montserrat';
        font-style: normal;
    }

    &__ContarRabTable {
        margin-top: 50px;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    &__createSmena {
        @extend %btn;
    }

    &__createSmena1 {
        margin-top: 20px;
        @extend %btn;
    }

    &__bimba1 {
        @extend %btn;
    }

    &__ForMargMonthChange {
        margin-top: 10px;
    }

    &__ForMargMonthChange1 {
        margin-top: 10px;
        margin-left: 30px;
    }

}

@media only screen and (min-width: 768px) and (max-width: 1024px) {
    .Ads {
        margin-top: 120px;
        margin-bottom: 150px;

        &__EdtFragBtn1 {
            width: 120px;
            font-size: 10px;
            padding-left: 10px;
            padding-right: 10px;
        }

        &__EdtModalZayvaMap {
            left: 25%;
        }

        &__EdtPerf {
            left: 20%;
        }


        &__center-container {
            position: fixed;
            top: 25%;
            left: 50%;
            transform: translate(-50%, -50%);

        }

        &__loading-overlay {
            position: fixed;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background: rgba(0, 0, 0, 0.7);
            display: flex;
            justify-content: center;
            align-items: center;
            z-index: 1000;
            overflow: hidden;
        }
    
        &__loading-wheel {
            border: 10px solid #f3f3f3;
            border-top: 10px solid #3498db;
            border-radius: 50%;
            width: 50px;
            height: 50px;
            animation: spin 1s linear infinite;
            margin: 20px auto;
        }
    
        @keyframes spin {
            0% {
                transform: rotate(0deg);
            }
    
            100% {
                transform: rotate(360deg);
            }
        }





        &__center-container1 {
            position: fixed;
            top: 22%;
            left: 45%;
            transform: translate(-50%, -50%);
        }

        &__center-container2 {
            position: fixed;
            top: 32%;
            left: 50%;
            transform: translate(-50%, -50%);
        }

        &__button {
            display: grid;
            grid-template-rows: auto;
            grid-column-gap: 150px;
            font-family: 'Montserrat';
            font-style: normal;
            font-size: 15px;
            max-width: 1024px;
            justify-content: center;
            align-items: center;
        }

        &__bimba {
            text-align: center;

            width: 300px;
        }

        &__bimba1 {

            width: 300px;
        }

        &__EdtFragBtn {
            width: 50px;
            height: 50px;
            padding-left: 5px;
            padding-right: 5px;
        }

        // &__EdtFragBtn{

        //     width: 10px;
        //     height: 50px;
        // }

        &__BtnForGraph {
            margin-top: 50px;
            display: grid;
            grid-template-columns: repeat(6, 210px);
            grid-column-gap: 50px;
            max-width: 150px;
            justify-content: center;
            align-items: center;
        }

        &__ThisBtnForGraph {
            height: 100px;
            width: 200px;
        }

        &__ForPlanCont {
            max-width: 100%;
            margin-top: 20px;
            margin-left: 70px;
            margin-right: 70px;
            overflow-x: auto;
        }

        &__ForPlanCont2 {
            max-width: 100%;
            margin-top: 20px;
            overflow-x: auto;
        }

        &__tableById4 {
            max-width: 100%;
            border-collapse: collapse;
        }

        &__tableById4 thead {
            position: sticky;
            top: 0;
        }

        &__tableById4 th,
        &__tableById4 td {
            min-width: 100px;
        }

        &__tableById2 {
            margin-top: 10px;
            margin-bottom: 20px;
            font-size: 12px;
            width: 100%;
            border-collapse: collapse;
        }

        &__tableById2 th,
        &__tableById2 td {
            min-width: 100px;
        }

        &__text {
            margin-top: 50px;
            padding-left: 50px;
            padding-right: 120px;
            margin-right: 70px;
            margin-left: -15px;
            display: grid;
            grid-template-columns: repeat(3, 200px);
            grid-template-rows: auto;
            grid-row-gap: 50px;
            grid-column-gap: 130px;
            max-width: 1024px;
        }

        &__ChangeBtnPhoto {
            margin-left: 60px;

        }

        &__text1 {
            margin-top: 50px;
            padding-left: 70px;
            padding-right: 120px;
            margin-right: 70px;
            display: grid;
            grid-template-columns: repeat(4, 250px);
            grid-column-gap: 50px;
            grid-template-rows: 100px;

        }

        &__text-input__getZ {
            margin-top: 50px;
            margin-left: 70px;
        }

        &__tableById {
            margin-top: 30px;
            margin-left: 70px;
            margin-right: 70px;
            max-width: 800px;
        }

        &__text-input {
            margin-top: 20px;
            margin-left: 20px;
        }

        &__selected-file {
            margin-top: 30px;
            margin-left: 20px;
        }

        &__photoObj {
            max-width: 800px;
            max-height: 800px;
        }

        &__InJobStatus {
            display: grid;
            grid-template-columns: repeat(3, 180px);
            grid-template-rows: 80px;
            grid-column-gap: 150px;
        }

        &__upload-button2 {
            margin-left: 20px;
            margin-top: 30px;
        }

        &__upload-button3 {
            margin-left: 0px;
            margin-top: 30px;
        }

        &__InJobStatusBtn {
            margin-left: 70px;
        }

        &__map {
            margin-top: 100px;
            margin-right: 20px;
            margin-left: 30px;
        }

        &__save {
            margin-left: 25%;
        }

        // &Ads__tableForMoble{
        //     width: 200px;
        //     overflow-y: scroll;
        // }
    }
}