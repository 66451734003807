.login {
  padding-top: 160px;
  max-width: 570px;
  margin: 0 auto 115px;

  &__form {
    position: relative;
  }

  &__links {
    text-align: center;
    margin-bottom: 40px;
  }

  &__link {
    color: $text-color;
    font-size: 30px;
    text-transform: uppercase;
    margin: 0 15px;
    cursor: pointer;

    &--active {
      color: $accent-color;
    }
  }

  &__link--active {
    color: $accent-color;
  }

  &__label {
    display: block;
    margin-bottom: 30px;
    color: $text-color;
    font-size: 20px;
  }

  &__input, .react-dadata__input {
    width: 100%;
    @extend %input;
  }

  &__btn {
    @extend %btn;
    display: block;
    width: 100%;
    margin-top: 5px;
  }

  &__error {
    color: $text-color;
    font-size: 18px;

    &:hover {
      color: $accent-color;
      transition: .2s;
    }
  }
}

.login__spinner {
  position: absolute;
  left: 46%;
  bottom: -18%;
}

.text-primary {
  color: $default-color !important;
}
