/* Основной контейнер для ButtonAddWear */
.ButtonAddWear_container,
.ButtonShowWear_container,
.ButtonExtraWear_container,
.Inz_container{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start; /* Располагаем контент сверху */
  padding-top: 5px; /* Отступ сверху */
  height: 100vh;
}

/* Контейнер для кнопок */
.ButtonAddWear_buttons,
.ButtonShowWear_buttons,
.ButtonExtraWear_buttons,
.Inz_buttons {
  display: flex;
  gap: 20px;
  margin-bottom: 10px;
  flex-wrap: wrap; /* Чтобы кнопки переходили на новую строку при необходимости */
  justify-content: center;
}

/* Общие стили для кнопок */
.ButtonAddWear_button,
.ButtonShowWear_button,
.ButtonExtraWear_button,
.Inz_button {
  padding: 15px 30px;
  font-size: 1.2rem;
  font-weight: bold;
  color: white;
  background-color: #1f487e;
  border: none;
  border-radius: 12px;
  box-shadow: 0px 8px 20px rgba(0, 0, 0, 0.15);
  cursor: pointer;
  transition: all 0.3s ease;
  position: relative;
  overflow: hidden;
  z-index: 1;
  opacity: 0;
  transform: translateY(20px);
  animation: fadeInUp 0.5s forwards ease-in-out;
}

/* Эффекты наведения и тени */
.ButtonAddWear_button::before,
.ButtonShowWear_button::before,
.ButtonExtraWear_button::before,
.Inz_button:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 0;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.3);
  transition: all 0.4s ease;
  z-index: 0;
}

.ButtonAddWear_button:hover::before,
.ButtonShowWear_button:hover::before,
.ButtonExtraWear_button:hover::before,
.Inz_button:hover:before {
  width: 100%;
}

.ButtonAddWear_button:hover,
.ButtonShowWear_button:hover,
.ButtonExtraWear_button:hover,
.Inz_button:hover {
  background-color: #163a65;
  transform: translateY(-3px);
  box-shadow: 0px 10px 25px rgba(0, 0, 0, 0.2);
}

/* Стили для плавного появления компонентов */
@keyframes fadeInUp {
  0% {
    opacity: 0;
    transform: translateY(20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.ButtonAddWear_content,
.ButtonShowWear_content,
.ButtonExtraWear_content,
.Inz_content{
  opacity: 0;
  transform: translateY(20px);
  animation: fadeInUp 0.5s forwards ease-in-out;
}

/* Адаптация под мобильные устройства */
@media (max-width: 768px) {
  /* Уменьшение размеров кнопок и отступов для планшетов */
  .ButtonAddWear_button,
  .ButtonShowWear_button,
  .ButtonExtraWear_button,
  .Inz_button {
    padding: 15px 40px;
    font-size: 1rem;
  }

  /* Уменьшение отступа сверху */
  .ButtonAddWear_container,
  .ButtonShowWear_container,
  .ButtonExtraWear_container
  .Inz_container {
    padding-top: 30px;
  }
}

@media (max-width: 480px) {
  /* Ещё большее уменьшение размеров для мобильных телефонов */
  .ButtonAddWear_button,
  .ButtonShowWear_button,
  .ButtonExtraWear_button,
  .Inz_button{
    padding: 10px 30px;
    font-size: 0.9rem;
    margin-bottom: 10px;
  }

  /* Контейнер кнопок в одну колонку на очень маленьких экранах */
  .ButtonAddWear_buttons,
  .ButtonShowWear_buttons,
  .ButtonExtraWear_buttons,
  .Inz_buttons {
    flex-direction: column;
    gap: 10px;
  }

  /* Уменьшение отступа сверху на мобильных устройствах */
  .ButtonAddWear_container,
  .ButtonShowWear_container,
  .ButtonExtraWear_container,
  .Inz_container {
    padding-top: 20px;
  }
}
