.main-content_inz {
  margin-top: 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.data_mane_inz {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 20px;
}

.data_input_inz {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  max-width: 1000px; /* Ограничиваем максимальную ширину, чтобы избежать слишком широких полей */
}

.input-field-inz-wear {
  width: calc(50% - 20px); /* Распределяем поля в две колонки с отступом между ними */
  padding: 20px; /* Увеличиваем внутренний отступ */
  margin: 15px 10px; /* Увеличиваем внешний отступ снизу и оставляем отступ слева/справа как раньше */
  border: 1px solid #777777; /* Добавляем серую обводку */
  border-radius: 5px;
  box-sizing: border-box;
}