// Основные переменные для синей цветовой схемы
$primary-color: #003b6f;
$secondary-color: #ffffff;
$text-color: #333;
$border-color: #606264;

.container {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  padding: 2rem;
  background-color: $secondary-color;
  border-radius: 12px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  width: 80%;
  margin: 0 auto;
}

.controls {
  display: flex;
  align-items: center;
  flex-direction: row;
  width: 100%;
}

.dropdown,
.dateInput {
  margin-right: 20px;
  width: 30%;
  padding: 1rem 1rem;
  font-size: 1rem;
  border: 2px solid $border-color;
  border-radius: 8px;
  background-color: #fff;
  color: $text-color;
  transition: border-color 0.3s, box-shadow 0.3s;
  cursor: pointer;

  &:focus,
  &:hover {
    border-color: $primary-color;
    box-shadow: 0 0 6px rgba(0, 59, 111, 0.5);
  }
}

.datePickerWrapper {
  position: relative;
  width: 100%;
}

.datePicker {
  position: absolute;
  top: calc(100% + 0.5rem);
  left: 0;
  z-index: 10;
  background-color: #fff;
  border: 2px solid $border-color;
  border-radius: 12px;
  padding: 1rem;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);

  .rdp {
    .rdp-day {
      padding: 0.75rem;
      border-radius: 6px;
      transition: background-color 0.2s, color 0.2s;

      &:hover {
        background-color: $primary-color;
        color: #fff;
      }
    }

    .rdp-day_selected {
      background-color: $primary-color;
      color: #fff;
      font-weight: bold;
    }

    .rdp-day_today {
      border: 2px solid $primary-color;
    }
  }
}

.table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 1.5rem;
  background-color: #fff;
  border-radius: 12px;
  overflow: hidden;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);

  th,
  td {
    text-align: left;
    padding: 1rem;
    border: 1px solid $border-color;
    color: $text-color;
  }

  th {
    background-color: $primary-color;
    color: #fff;
    font-weight: bold;
  }

  td {
    background-color: $secondary-color;
  }

  tr:nth-child(even) td {
    background-color: lighten($secondary-color, 5%);
  }
}


.title {
  font-size: 1.8rem;
  font-weight: bold;
  color: $text-color; // черный
  margin-bottom: 1.5rem;
  text-align: center;
  text-transform: uppercase;
  letter-spacing: 0.1rem;
}