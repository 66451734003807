$accent-color: #ff4848;
$text-color: #a5a7a8;
$default-color: #1f487e;
$bg-color: rgb(223, 223, 223);

%btn {
  padding: 14px 35px;
  text-transform: uppercase;
  background-color: #1f487e;
  color: #fff;
  border: none;
  cursor: pointer;
  transition: .2s;
  box-shadow: 0px, 7px, 20px, 0px, rgba(255, 17, 48, 0.3);

  &:hover {
    background-color: $accent-color;
    color: #000;
  }
}

%input {
  display: block;
  margin-bottom: 10px;
  font-size: 18px;
  color: $text-color;
  border: none;
  margin-top: 4px;
  height: 60px;
  padding: 0 15px;
  color: #000;
}

%checkbox {
  width: 1px;
  height: 1px;
  overflow: hidden;
  clip: rect(0 0 0 0);
  position: absolute;
}

%checkbox__span {
  width: 16px;
  height: 16px;
  background-color: #1f487e;
  position: absolute;
  margin-left: -16px;
  margin-top: -7px;
}

%main__animation {
  background: linear-gradient(270deg, #3d5d99, #6d93da);
  background-size: 400% 400%;

  -webkit-animation: AnimationName 29s ease infinite;
  -moz-animation: AnimationName 29s ease infinite;
  animation: AnimationName 29s ease infinite;


  @-webkit-keyframes AnimationName {
    0% {
      background-position: 0% 50%
    }

    50% {
      background-position: 100% 50%
    }

    100% {
      background-position: 0% 50%
    }
  }

  @-moz-keyframes AnimationName {
    0% {
      background-position: 0% 50%
    }

    50% {
      background-position: 100% 50%
    }

    100% {
      background-position: 0% 50%
    }
  }

  @keyframes AnimationName {
    0% {
      background-position: 0% 50%
    }

    50% {
      background-position: 100% 50%
    }

    100% {
      background-position: 0% 50%
    }
  }

  
}
%main__animation-text {
  background: linear-gradient(270deg, #3d5d99, #6d93da);
  background-size: 400% 400%;
  -webkit-background-clip: text;
  -moz-background-clip: text;
  background-clip: text;
  color: transparent;

  -webkit-animation: AnimationNameText 29s ease infinite;
  -moz-animation: AnimationNameText 29s ease infinite;
  animation: AnimationNameText 29s ease infinite;
}

@-webkit-keyframes AnimationNameText {
  0% {
    background-position: 0% 50%;
  }

  50% {
    background-position: 100% 50%;
  }

  100% {
    background-position: 0% 50%;
  }
}

@-moz-keyframes AnimationNameText {
  0% {
    background-position: 0% 50%;
  }

  50% {
    background-position: 100% 50%;
  }

  100% {
    background-position: 0% 50%;
  }
}

@keyframes AnimationNameText {
  0% {
    background-position: 0% 50%;
  }

  50% {
    background-position: 100% 50%;
  }

  100% {
    background-position: 0% 50%;
  }
}