/* Основной контейнер */
.AddWear_container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start; /* Располагаем контент сверху */
  padding-top: 50px; /* Отступ сверху */
  height: 100vh;
  margin-top: 100px;
}

/* Контейнер для кнопок */
.AddWear_buttons {
  display: flex;
  gap: 20px;
  margin-bottom: 30px;
  flex-wrap: wrap; /* Чтобы кнопки переходили на новую строку при необходимости */
  justify-content: center;
}

/* Общие стили для кнопок */
.AddWear_button {
  padding: 15px 40px;
  font-size: 1.2rem;
  font-weight: bold;
  color: white;
  background-color: #1f487e;
  border: none;
  border-radius: 12px;
  box-shadow: 0px 8px 20px rgba(0, 0, 0, 0.15);
  cursor: pointer;
  transition: all 0.3s ease;
  position: relative;
  overflow: hidden;
  z-index: 1;
  opacity: 0;
  transform: translateY(20px);
  animation: fadeInUp 0.5s forwards ease-in-out;
}

/* Эффекты наведения и тени */
.AddWear_button::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 0;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.3);
  transition: all 0.4s ease;
  z-index: 0;
}

.AddWear_button:hover::before {
  width: 100%;
}

.AddWear_button:hover {
  background-color: #163a65;
  transform: translateY(-3px);
  box-shadow: 0px 10px 25px rgba(0, 0, 0, 0.2);
}

/* Стили для плавного появления */
@keyframes fadeInUp {
  0% {
    opacity: 0;
    transform: translateY(20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

/* Контент, который появляется */
.AddWear_content {
  opacity: 0;
  transform: translateY(20px);
  animation: fadeInUp 0.5s forwards ease-in-out;
}

/* Адаптация под мобильные устройства */
@media (max-width: 768px) {
  /* Уменьшение размеров кнопок и отступов для планшетов */
  .AddWear_button {
      padding: 12px 30px;
      font-size: 1rem;
  }

  /* Уменьшение отступа сверху */
  .AddWear_container {
      padding-top: 30px;
      margin-top: 80px;
  }
}

@media (max-width: 480px) {
  /* Ещё большее уменьшение размеров для мобильных телефонов */
  .AddWear_button {
      padding: 10px 25px;
      font-size: 0.9rem;
      margin-bottom: 10px;
  }

  /* Контейнер кнопок в одну колонку на очень маленьких экранах */
  .AddWear_buttons {
      flex-direction: column;
      gap: 10px;
  }

  /* Уменьшение отступа сверху на мобильных устройствах */
  .AddWear_container {
      padding-top: 20px;
      margin-top: 50px;
  }
}
