$primary-color: #005f7a;
$secondary-color: #eaf8ff;
$gray-bg: #f5f5f5;
$button-color: #003b6f;
$button-hover: rgba(0, 135, 220, 0.6);

.container {
  padding: 2rem;
  background: $gray-bg;
  border-radius: 16px;
}

.header {
  display: flex;
  justify-content: space-between;
  margin-bottom: 2rem;

  .dropdownGroup,
  .dateWrapper {
    display: flex;
    flex-direction: column;
    gap: 10px;
    width: 100%;
  }

  .label {
    font-size: 1.2rem;
    margin-bottom: 0.5rem;
    color: black;
  }

  .dropdown {
    padding: 0.8rem 1rem;
    font-size: 1.1rem;
    border: 2px solid #003b6f;
    border-radius: 12px;
    color: black; // черный
    cursor: pointer;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    transition: all 0.3s ease;
  
    &:hover,
    &:focus {
      border-color: darken(#003b6f, 10%);
      outline: none;
      box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);
    }
  }
}

.section {
  margin-bottom: 2.5rem;
  padding: 1.5rem;
  border-radius: 12px;
  flex: 1;
  background: #fff;
  border-radius: 10px;
  padding: 20px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.493);

  h2 {
    font-size: 1.5rem;
    color: $primary-color;
    margin-bottom: 2rem;
  }

  h4 {
    margin-bottom: 1rem;
  }

  p {
    font-size: 1rem;
    margin-bottom: 1rem;
    color: darken($gray-bg, 15%);
  }

  .row {
    display: flex;
    justify-content: space-between;
    margin-bottom: 1rem;

    .input {
      width: 30%;
      padding: 0.8rem;
      font-size: 1rem;
      border: 2px solid #005f7a;
      border-radius: 8px;
      background: white;
      transition: border-color 0.3s ease;

      &:hover,
      &:focus {
        border-color: #005f7a;
        outline: none;
      }
    }
  }

  .saveButton {
    display: block;
    width: 100%;
    padding: 0.8rem;
    font-size: 1rem;
    background: $button-color;
    color: white;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    transition: background 0.3s ease;
    margin-bottom: 1rem;

    &:hover {
      background: $button-hover;
    }
  }
}
