.tabs {
  padding-top: 100px;
  padding-bottom: 50px;

  &__top {
    display: grid;
    grid-template-columns: 34% 33% 33%;
    border-bottom: 1px solid #1f487e;
    margin-bottom: 40px;
  }

  &__top-item {
    font-size: 20px;

    & + .tabs__top-item {
      margin-left: 55px;
    }

    color: $text-color;
    font-weight: bold;
    height: auto;
    background-color: transparent;
    margin-bottom: 0;
    padding-bottom: 10px;

    &:hover {
      color: #000;
      transition: .2s;
    }
  }

  &__top-item--active {
    color: #000;
    position: relative;

    &::before {
      content: '';
      position: absolute;
      left: 0;
      right: 0;
      width: 100%;
      bottom: -1px;
      height: 1px;
      background-color: $accent-color;
    }
  }

  &__content-switch {
    display: flex;
    margin-bottom: 15px;
  }

  &__content-switch__text {
    font-size: 19px;
    color: $text-color;

    &--active {
      color: #000;
    }
  }

  &__content-switch__label {
    margin: 0 10px;
  }

  &__content-switch__input {
    position: relative;
    width: 60px;
    height: 30px;
    -webkit-appearance: none;
    background-color: #c6c6c6;
    outline: none;
    border-radius: 20px;
    box-shadow: inset 0 0 5px rgba(0, 0, 0, .2);
    cursor: pointer;

    &:checked {
      background-color: #1f487e;
    }

    &::before {
      content: '';
      position: absolute;
      width: 30px;
      height: 30px;
      border-radius: 20px;
      top: 0;
      left: 0;
      background-color: #fff;
      transition: .3s;
      transform: scale(1.1);
      box-shadow: 0 2px 5px rgba(0, 0, 0, .2);
    }

    &:checked::before {
      left: 30px;
    }
  }

  &__content-item {
    display: none;
    color: $text-color;
    max-width: 1080px;
    margin-bottom: 100px;

    &.tabs__content-item--active {
      display: block;
    }
  }
}

.tabs-wear {
  padding-top: 100px;
  padding-bottom: 50px;

  &__top {
    display: grid;
    grid-template-columns: 50% 50%;
    text-align: center;
    border-bottom: 1px solid #1f487e;
    margin-bottom: 40px;
  }

  &__top-item {
    font-size: 20px;

    & + .tabs__top-item {
      margin-left: 55px;
    }

    color: $text-color;
    font-weight: bold;
    height: auto;
    background-color: transparent;
    margin-bottom: 0;
    padding-bottom: 10px;

    &:hover {
      color: #000;
      transition: .2s;
    }
  }

  &__top-item--active {
    color: #000;
    position: relative;

    &::before {
      content: '';
      position: absolute;
      left: 0;
      right: 0;
      width: 100%;
      bottom: -1px;
      height: 1px;
      background-color: $accent-color;
    }
  }

  &__content-switch {
    display: flex;
    margin-bottom: 15px;
  }

  &__content-switch__text {
    font-size: 19px;
    color: $text-color;

    &--active {
      color: #000;
    }
  }

  &__content-switch__label {
    margin: 0 10px;
  }

  &__content-switch__input {
    position: relative;
    width: 60px;
    height: 30px;
    -webkit-appearance: none;
    background-color: #c6c6c6;
    outline: none;
    border-radius: 20px;
    box-shadow: inset 0 0 5px rgba(0, 0, 0, .2);
    cursor: pointer;

    &:checked {
      background-color: #1f487e;
    }

    &::before {
      content: '';
      position: absolute;
      width: 30px;
      height: 30px;
      border-radius: 20px;
      top: 0;
      left: 0;
      background-color: #fff;
      transition: .3s;
      transform: scale(1.1);
      box-shadow: 0 2px 5px rgba(0, 0, 0, .2);
    }

    &:checked::before {
      left: 30px;
    }
  }

  &__content-item {
    display: none;
    color: $text-color;
    max-width: 1080px;
    margin-bottom: 100px;

    &.tabs__content-item--active {
      display: block;
    }
  }
}

.pay-detail {
  font-size: 19px;

  &__input-wrapper {
    display: grid;
    grid-template-columns: 50% 50%;
    grid-column-gap: 60px;
  }

  &__label {
    margin-bottom: 30px;
    display: block;
  }

  &__input, .react-dadata__input {
    display: block;
    width: 100%;
    border: none;
    background-color: #f6f6f6;
    margin-top: 4px;
    height: 60px;
    padding: 0 15px;

    &:invalid {
      border: 1px solid $accent-color;
    }
  }

  &__equipment-title {
    font-weight: bold;
  }

  &__btn {
    display: inline;
    margin: 20px;
    @extend %btn;
  }

  &__walltype-select {
    display: block;
    width: 100%;
    border: none;
    background-color: #f6f6f6;
    margin-top: 4px;
    height: 60px;
    padding: 0 15px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    outline: none;
  }

  &__region-select {
    display: block;
    width: 100%;
    border: none;
    background-color: #f6f6f6;
    margin-top: 4px;
    height: 60px;
    padding: 0 15px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    outline: none;

    option:nth-child(1) {
      font-weight: bold;
    }
  }

  &__equipment-wrapper {
    display: grid;
    grid-template-columns: 360px 360px 360px;
    grid-column-gap: 5px;
    margin-top: 30px;
    margin-bottom: 20px;
  }

  &__checkbox-label {
    padding-bottom: 15px;
    border-bottom: 1px solid #ececec;
    margin-bottom: 10px;
    display: flex;
    justify-content: space-between;
    max-width: 320px;
    align-items: center;

    &:hover {
      span {
        color: #000;
        cursor: pointer;
        transition: .2s;
      }
    }
  }

  &__checkbox {
    @extend %checkbox;

    &:checked + .pay-detail__checkbox-span {
      background-image: url('../../images/check-on.svg');
      background-size: 85%;
      transition: none;
      background-repeat: no-repeat;
      background-position: center;
    }
  }

  &__checkbox-span {
    @extend %checkbox__span;
  }

  &__checkbox-text {
    padding-right: 20px;
  }
}

.span--active {
  color: #000;
}

.base-table {
  border: 1px solid #000;
  border-collapse: collapse;
  table-layout: fixed;

  thead th:nth-child(1) {
    width: 30%;
  }

  thead th:nth-child(2) {
    width: 45%;
  }

  thead th:nth-child(3) {
    width: 15%;
  }

  thead th:nth-child(4) {
    width: 15%;
  }


  th,
  td {
    padding: 10px;
    color: #000;
    text-align: start;
    //border: 1px solid #000;
  }

  tr:last-child {
    font-weight: bold;
  }

  tbody td:nth-child(3) {
    text-align: center;
  }
}

.detalisation-table {
  .monthyear {
    th:last-child {
      text-align: center;
    }
  }
}

.pay-detail__form-lexa {
  display: flex;
}

.lexabtn {
  transform: translateY(11px);
}

.lexabtn1 {
  margin-bottom: 0;
}

.lexaflex {
  display: flex;
  align-items: center;
  justify-content: space-between;
}