.ForAds {
    background: linear-gradient(rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.3)), url("../../images/ForPageAds.jpg");
    background-size: cover;
    min-height: 1200px;
    background-position: center;
    background-repeat: no-repeat;
    background-attachment: fixed;

    &__title{
        color: #fff;
        padding-top: 150px;
        text-align: center;
        font-size: 70px;
    }
    &__inf{
        color: #fff;
        padding-top: 100px;
        font-size: 18px;
        max-width: 750px;
        margin-left: auto;
        text-indent: 25px;
        text-align: left;
        font-style: italic;
    }
    &__block1{
        background-color: #fff;
        margin-top: 150px;
        max-width: 100%;
        max-height: 500px;
    }
    &__block1-describe1{
        text-indent: 25px;
        padding-top: 30px;
        padding-bottom: 30px;
        font-size: 25px;
        text-align: justify;
    }
    &__block2{
        background-color: #fff;
        margin-top: 150px;
        padding-bottom: 10px;
        max-width: 100%;
        max-height: 500px;
    }
    &__block2-describe2{
        padding-top: 30px;
        padding-bottom: 10px;
        font-size: 25px;
        text-align: justify;
    }
    &__ForMargin{
        margin-top: 100px;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: row;
        height: 150px;
        background-color: #fff

    }
    &__ForVBtn{
        margin-right: 50px;
    }
    &__TV{
        margin-bottom: 30px;
        text-align: center;
        font-weight: bold;
        font-size: 54px;
        
    }
    &__DemoVideo{
        @extend %btn;
        margin-right: 40px;
        text-align: center;
        display: block;
        width: 200px;
    }
    &__VBloc{
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
    }
    &__BlocV{
        padding-top: 10px;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: #fff;
        padding-bottom: 100px;
    
    }
}