.wearmenu {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 50vh; /* Высота равная высоте видимой области страницы */
  }
  
  .wearmenu-btn-wear {
    /* Ваши стили для кнопок */
    background-color: #1f487e;
    color: white;
    border: none;
    padding: 15px 25px;
    margin: 10px;
    border-radius: 8px;
    cursor: pointer;
    transition: all 0.3s ease;
    font-size: 50px;
  }
  
  .wearmenu-btn-wear:hover {
    opacity: 0.8;
  }
  
  .wearmenu-btn-wear:active {
    transform: translateY(10px);
  }
  