.ModalWindow {
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10;
  backdrop-filter: blur(10px);
  display: flex;
  justify-content: center;
  align-items: center;

  &__inner {
    width: 700px;
    // height: 400px;
    background-color: #fff;
    border-radius: 10px;
    position: relative;
  }

  &__close-button {
    width: 35px;
    height: 35px;
    border: none;
    background-color: transparent;
    background-image: url('../../images/close.svg');
  }

  &__top {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #3d5d99;
    padding: 20px;
    color: #3d5d99;
  }

  &__center {
    padding: 20px;
  }

  p{
    margin-bottom: 15px;
    font-size: 18px;
  }

  ul{
    padding-left: 10px;
    list-style: none;
    margin-bottom: 15px;
    font-size: 18px;
  }

  .create__smena-btn{
    margin: 0 auto;
  }
}