.file-upload-button {
  display: flex;
  input[type="file"] {
    background-color: #4CAF50;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
    margin-right: 15px;
    color: #fff;

    &:hover {
      background-color: #45a049;
    }
  }

  input::-webkit-file-upload-button {
    display: none;
  }

  input::before {
    content: "Выберите файлы";
    display: inline-block;
    outline: none;
    font-weight: 700;
    padding: 5px 8px;
    border-radius: 5px;
    border: 1px solid #fff;
    margin-right: 15px;
  }

  .upload-btn {
    display: flex;
    align-items: center;
    background-color: #4CAF50;
    color: white;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;

    &:hover {
      background-color: #45a049;
    }
  }
}