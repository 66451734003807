.container {
  border-radius: 8px;
  padding: 2rem;
  max-width: 600px;
  margin: 3rem auto;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  text-align: center;
  width: 90%;
  background: #f9f9f9;

  @keyframes fadeIn {
    from {
      opacity: 0;
      transform: translateY(10px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
}

.title {
  font-size: 1.8rem;
  color: #003b6f;
  font-weight: 600;
  margin-bottom: 1.5rem;
  text-transform: none;
}

.description {
  font-size: 1rem;
  color: #555;
  margin-bottom: 1rem;
}

.selectWrapper {
  position: relative;
  display: inline-block;
  width: 100%;
}

.select {
  width: 100%;
  padding: 0.8rem 1.2rem;
  font-size: 1rem;
  border: 1px solid #ccc;
  border-radius: 6px;
  margin-bottom: 1.5rem;
  background: #fff;
  color: #333;
  appearance: none;
  transition: all 0.2s ease;

  &:hover,
  &:focus {
    border-color: #007bff;
    outline: none;
  }
}

.arrow {
  content: "";
  position: absolute;
  top: 50%;
  right: 1rem;
  transform: translateY(-50%);
  border: 6px solid transparent;
  border-top-color: #555;
  pointer-events: none;
}

.info {
  margin-top: 1.5rem;
  animation: fadeIn 0.5s ease-in-out;
}

.button {
  display: inline-block;
  padding: 0.8rem 1.5rem;
  background: #003b6f;
  color: #fff;
  font-size: 1rem;
  font-weight: 500;
  border: none;
  border-radius: 6px;
  cursor: pointer;
  transition: all 0.3s ease;

  &:hover {
    background: #0056b3;
  }
}

.message {
  font-size: 1rem;
  color: #555;
  margin-top: 0.5rem;
  font-style: italic;
}
