html{
  scroll-behavior: smooth;
}

body {
  color: #000;
  font-size: 16px;
  font-family: 'Roboto', sans-serif;
}

a {
  color: #fff;
  font-size: 16px;
  text-decoration: none;

  &:hover {
    color: $accent-color;
    transition: .2s;
  }
}

.container {
  max-width: 1170px;
  margin: 0 auto;
  padding: 0 15px;
}

.large__container {
  position: absolute;
  left: 0;
  right: 0;
  max-width: 1500px;
  margin: 0 auto;
  padding: 0 40px;
}