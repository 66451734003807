.downloadBtn {
  margin-top: 30px;
  max-width: 200px;
  position: relative;
  background-color: RoyalBlue;
  box-shadow: 0 0 0.8em 0 rgba(65, 105, 225, 0.8);
  border: none;
  padding: 12px;
  width: 200px;
  color: #fff;
  text-align: center;
  transition-duration: 0.4s;
  text-decoration: none;
  overflow: hidden;
  cursor: pointer;
  font-size: 24px;

  &:hover {
    background: #fff;
    box-shadow: 0px 2px 10px 5px #97B1BF;
    color: #000;
  }
}