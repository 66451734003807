.container {
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 20px;
  background-color: #f9f9f9;
  border-radius: 10px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
}

.dropdownGroup {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.dropdown {
  padding: 0.8rem 1rem;
  font-size: 1.1rem;
  border: 2px solid #003b6f;
  border-radius: 12px;
  color: black; // черный
  cursor: pointer;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease;

  &:hover,
  &:focus {
    border-color: darken(#003b6f, 10%);
    outline: none;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);
  }
}

.sectionGroup {
  display: flex;
  flex-wrap: wrap;
  gap: 40px;
  margin: 20px;
}

.section {
  flex: 1;
  min-width: 300px;
  background: #fff;
  border-radius: 10px;
  padding: 20px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.493);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.section:hover {
  transform: translateY(-5px);
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
}

.section h3 {
  margin-bottom: 15px;
  color: #333;
  display: inline-block;
}

.formGroup {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin: 10px;
}

.formGroup label {
  font-weight: bold;
  color: #555;
}

.formGroup input {
    width: 100%;
    padding: 0.8rem;
    font-size: 1rem;
    border: 2px solid #005f7a;
    border-radius: 8px;
    background: white;
    transition: border-color 0.3s ease;

    &:hover,
    &:focus {
      border-color: #005f7a;
      outline: none;
    }
  }

.addButton {
  padding: 15px 20px;
  background-color: #003b6f;
  color: #fff;
  border: none;
  border-radius: 5px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.2s ease;
}

.addButton:hover {
  background-color: #0056b3;
}

.addButton:active {
  transform: scale(0.98);
}

@media (max-width: 768px) {
  .sectionGroup {
    flex-direction: column;
  }
}


.sectionGroupContainer {
  display: flex;
  flex-direction: row;
  padding: 10px;
}