// @import url('../../images/add_button.svg');

.dynamicValuesRso {
  &__changeWPS-form {
    margin-bottom: 10px;
  }

  &__changeWPS-title {
    text-align: center;
    font-size: 20px;
    color: $text-color;
    font-weight: bold;
    margin-bottom: 15px;
  }

  &__chooseSmensBox {
    display: flex;
    margin: 0 auto;
    max-width: 650px;
    align-items: center;
    margin-bottom: 30px;
    justify-content: center;
  }

  &__chooseSmensBox-submit{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    p{
      margin-bottom: 20px;
    }
  }

  &__changeWPS-select {
    font-size: 18px;
    background-color: transparent;
    padding: 8px 20px;
    outline: none;
    max-width: 250px;
    transform: translateY(1px);
    cursor: pointer;
    margin-bottom: 10px;
  }

  &__changeWPS-btn {
    @extend %btn;
    line-height: 0;
    padding: 20px 40px;
  }

  &__input-new {
    height: 40px;
    margin-top: 0;
    width: 290px;
  }

  &__tabs {
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid #1f487e;
    margin-bottom: 40px;
  }

  &__tabs-disp {
    display: flex;
    justify-content: space-evenly;
    border-bottom: 1px solid #1f487e;
    margin-bottom: 40px;
  }

  &__tab {
    border: none;
    cursor: pointer;
    font-size: 18px;
    color: $text-color;
    font-weight: bold;
    height: auto;
    background-color: transparent;
    margin-bottom: 0;
    padding-bottom: 10px;
    text-align: center;

    &:hover {
      color: #000;
      transition: .2s;
    }
  }

  &__tab--active {
    color: #000;
    position: relative;

    &::before {
      content: '';
      position: absolute;
      left: 0;
      right: 0;
      width: 100%;
      bottom: -1px;
      height: 1px;
      background-color: $accent-color;
    }
  }

  &__changetype {
    display: grid;
    justify-content: center;
    grid-template-columns: repeat(2, 40%);
    grid-row-gap: 20px;
    margin-bottom: 20px;
  }

  &__content {
    margin: 0 auto;
    margin-top: 120px;
    padding: 0 15px;
    max-width: 1170px;

    .dynamicValuesRso__content {
      margin-top: 0;
    }
  }

  &__title {
    margin: 30px 0;
    text-align: center;
  }

  &__addition {
    text-align: center;
    margin-bottom: 20px;
    color: $accent-color;
  }

  &__dynamicValuesBtn {
    @extend %btn;
    margin: 0 20px;
    display: block;
  }

  &__dynamicValuesBtn-a {
    text-align: center;
  }

  &-tableOleg {
    margin: 0 auto;
    border-collapse: collapse;
    border: 1px solid #000;
    table-layout: fixed;

    th, td {
      padding: 10px 7px;
    }

    th {
      font-weight: bold;
      border: 1px solid #000;
      text-align: center;
    }

    td {
      font-weight: normal;
      border: 1px solid #000;
      text-align: center;
    }
  }

  &-table {
    // margin: 0 auto;
    // border-collapse: collapse;
    // border: 1px solid #000;
    // table-layout: fixed;
    // margin-bottom: 20px;
    background: white;
    border-radius: 3px;
    border-collapse: collapse;
    // height: 320px;
    margin: auto;
    // max-width: 600px;
    padding: 5px;
    width: 100%;
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
    animation: float 5s infinite;
    align-items: center;
    margin-bottom: 20px;


    th {
      color: #D5DDE5;
      background: $default-color;
      border-bottom: 2px solid $text-color;
      border-right: 1px solid #343a45;
      font-size: 16px;
      font-weight: bold;
      padding: 14px;
      text-align: center;
      text-shadow: 0 1px 1px rgba(0, 0, 0, 0.1);
      vertical-align: middle;
    }

    th:first-child {
      border-top-left-radius: 3px;
    }

    th:last-child {
      border-top-right-radius: 3px;
      border-right: none;
    }

    tr {
      border-top: 1px solid #C1C3D1;
      border-bottom: 1px solid #C1C3D1;
      color: #666B85;
      font-size: 16px;
      font-weight: normal;
      text-shadow: 0 1px 1px rgba(256, 256, 256, 0.1);
    }

    // tr:hover td {
    //   background: #4E5066;
    //   color: #FFFFFF;
    //   border-top: 1px solid #22262e;
    // }

    tr:first-child {
      border-top: none;
    }

    tr:last-child {
      border-bottom: none;
    }

    tr:nth-child(odd) td {
      background: #EBEBEB;
    }

    // tr:nth-child(odd):hover td {
    //   background: #4E5066;
    // }

    tr:last-child td:first-child {
      border-bottom-left-radius: 3px;
    }

    tr:last-child td:last-child {
      border-bottom-right-radius: 3px;
    }

    td {
      background: #FFFFFF;
      padding: 16px;
      text-align: left;
      vertical-align: middle;
      font-weight: 300;
      font-size: 16px;
      text-shadow: -1px -1px 1px rgba(0, 0, 0, 0.1);
      border-right: 1px solid #C1C3D1;
    }

    td:last-child {
      border-right: 0px;
    }

    &__alert {
      color: $accent-color;
    }

    &__min-font__size {
      direction: ltr;
      position: relative;

      th {
        font-size: 13px;
        text-align: center;
        padding: 15px 1px;
        position: -webkit-sticky;
        /* for Safari */
        position: sticky;
        top: 0;
      }

      tr:nth-child(odd) td {
        background-color: #fff;
      }

      td {
        font-size: 11px;
        font-weight: bold;
        text-align: center;
        padding: 5px 1px;
        color: #000;
        width: 50px;
        position: -webkit-sticky;
        /* for Safari */
        position: sticky;
        left: 0;
      }

      tr .dynamicValuesRso__td-even,
      tr:nth-child(odd) .dynamicValuesRso__td-even {
        background-color: #EBEBEB;
      }

      thead th:first-child,
      tbody td:first-child {
        left: 0;
        z-index: 1;
      }

    }

  }

  &-noData {
    margin-bottom: 30px;
    h5 {
      font-size: 20px;
      margin-bottom: 10px;
    }

    ul {
      list-style: none;
      padding: 0;
    }

    li {
      margin-bottom: 5px;

      &::before {
        content: '- '
      }
    }
  }

  &__btn {
    @extend %btn;
    display: block;
  }

  &-scroll__table-box {
    direction: rtl;
    overflow: auto;
    width: 100%;
    margin-bottom: 10px;
  }

  &__form {
    display: grid;
    grid-template-columns: repeat(3, 33%);
    grid-gap: 20px;
    margin-bottom: 40px;
    align-items: end;

    &__label {
      margin: auto;
      position: relative;

    }

    .dynamicValuesRso__btn {
      grid-column-start: 1;
      grid-column-end: 4;
      margin: 0 auto;
      margin-left: 0;
    }
  }

  &__change-select {
    display: block;
    height: 60px;
    width: 100%;
    transform: translateY(3px);
  }

  &__closeSmena-form {
    display: flex;
    justify-content: center;
  }

  &__input {
    width: 95%;
  }

  &__form-repair {
    display: block;

    label {
      max-width: 450px;
      margin-bottom: 20px;
    }

    select {
      margin-left: 10px;
    }
  }

  &__operatorDb-box {
    display: flex;
    justify-content: center;
    margin-bottom: 15px;
  }

  &__operatorDb-box__inner {
    display: flex;
    padding: 15px 30px;
    border: 1px solid #000;
    border-radius: 7px;
    align-items: center;
  }

  &__changeWPS-operatorDb__form {
    display: flex;
  }

  &__addButton-box {
    width: 53px;
    height: 53px;
    position: relative;
    margin-right: 10px;

    &:hover {
      svg {
        path {
          stroke: $accent-color;
          transition: all .3s;
        }
      }
    }

    svg {
      position: absolute;

      path {
        stroke: $default-color;
      }
    }
  }

  &__addButton {
    padding: 0;
    font-size: 0;
    position: absolute;
    width: 53px;
    height: 53px;
    cursor: pointer;
    background-color: transparent;
    border: none;

    &::before {
      position: absolute;
      content: '';
      background-image: url('../../images/add_button.svg');
      width: 53px;
      height: 53px;
      background-size: cover;
      background-repeat: no-repeat;
      top: 0;
      left: 0;
    }
  }

  &__changeWPS-operatorDb__select {
    margin-right: 10px;
    padding: 5px 20px;
    max-width: 240px;
    cursor: pointer;
  }

  &__changeWPS-operatorDb__btn {
    @extend %btn;
    grid-column-start: 1;
    // height: 60px;
    max-width: 338px;
  }

  &__operatorDb-box__input-box {
    padding: 15px 30px;
    border: 1px solid #000;
    border-radius: 7px;
  }

  &__operatorDb-title {
    color: $text-color;
    font-size: 19px;
    text-align: center;
    margin-bottom: 15px;
  }
}

.required__field {
  color: $accent-color;
}


// @import url("https://fonts.googleapis.com/css?family=Karla|Space+Mono");

:root {
  --contentHeight: 100%;
  // --sectionWidth: 700px;
}


details {
  .dynamicValuesRso__form {
    width: 92%;
  }
}


summary {
  margin-top: 20px;
  display: block;
  cursor: pointer;
  // padding: 10px;
  font-family: "Space Mono", monospace;
  font-size: 22px;
  transition: .3s;
  border-bottom: 2px solid;
  user-select: none;
  margin-bottom: 30px;
}

details>div {
  // display: flex;
  flex-wrap: wrap;
  overflow: auto;
  //height: 100%;
  user-select: none;
  // padding: 0 20px;
  // font-family: "Karla", sans-serif;
  line-height: 1.5;
}

details>div>img {
  align-self: flex-start;
  max-width: 100%;
  margin-top: 20px;
}

details>div>p {
  flex: 1;
}

details[open]>summary {
  color: $accent-color;
  transition: all .3s;
}

@media (min-width: 768px) {
  details[open]>div>p {
    opacity: 0;
    animation-name: showContent;
    animation-duration: 0.6s;
    animation-delay: 0.2s;
    animation-fill-mode: forwards;
    margin: 0;
    // padding-left: 20px;
  }

  details[open]>div {
    animation-name: slideDown;
    animation-duration: 0.3s;
    animation-fill-mode: forwards;
    transition: all .3s;
  }

  details[open]>div>img {
    opacity: 0;
    height: 100%;
    margin: 0;
    animation-name: showImage;
    animation-duration: 0.3s;
    animation-delay: 0.15s;
    animation-fill-mode: forwards;
  }
}

@keyframes slideDown {
  from {
    opacity: 0;
    height: 0;
    padding: 0;
    transition: all .3s;
  }

  to {
    opacity: 1;
    height: var(--contentHeight);
    // padding: 20px;
    transition: all .3s;
  }
}

@keyframes showImage {
  from {
    opacity: 0;
    clip-path: inset(50% 0 50% 0);
    transform: scale(0.4);
    transition: all .3s;
  }

  to {
    opacity: 1;
    clip-path: inset(0 0 0 0);
    transition: all .3s;
  }
}

@keyframes showContent {
  from {
    opacity: 0;
    transition: all .3s;
  }

  to {
    opacity: 1;
    transition: all .3s;
  }
}

.dispatcherCheck__tabs {
  justify-content: space-around;
  margin-top: 130px;

  a {
    font-size: 22px;
  }
}

.dispatcherCheck__Btns-box {
  .dynamicValuesRso__dynamicValuesBtn {
    background-color: $text-color;
    color: #000;

    &:hover {
      background-color: $default-color;
      color: $accent-color;
    }
  }

  .dynamicValuesRso__tab--active {
    color: #fff;
    background-color: $default-color;

    &:hover {
      color: #fff;
    }
  }
}

.dynamicValuesRso__dynamicAllDaylyReport {
  .dynamicValuesRso__changeWPS-form {
    display: flex;
    flex-direction: column;
  }

  .dynamicValuesRso__changeWPS-btn {
    margin-top: 15px;

  }
}

.dynamicValuesRso__changeWPS-btn {
  margin-bottom: 10px;
}

.dynamicValuesRso__dispatcher-form {
  display: flex;
  justify-content: center;
  align-items: center;
  // margin: 0 auto;
  margin-top: 50px;
  margin-bottom: 40px;

  div {
    display: flex;
    align-items: center;
  }

  span {
    margin-right: 10px;
  }

  div+div {
    margin-left: 40px;
  }
}

.dynamicValuesRso__dispatcher-form__btn {
  margin-left: 20px;
  margin-bottom: 0;
}

.dynamicValuesRso__alert {
  color: $accent-color;
  padding-bottom: 20px;
}

.dynamicValuesRso__dispatcher-repairCheck{
  display: flex;
  justify-content: center;
}

.disp-page {
  margin-top: 20px;
  justify-content: center;
  a+a{
    margin-left: 80px;
  }
}

.disp-page .dynamicValuesRso__tab{
  font-size: 20px;
}