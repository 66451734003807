.ForVzy{
    background: linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)), url("../../images/ForVzy.jpg");
    background-size: cover;
    min-height: 600px;
    background-position: center;
    background-repeat: no-repeat;
    background-attachment: fixed;
    
    &__title{
        color: #fff;  
        padding-top: 150px;
        text-align: center;
        font-size: 70px;
    }
    &__inf{
        color: #fff;
        padding-top: 100px;
        font-size: 18px;
        max-width: 750px;
        margin-left: auto;
        text-indent: 25px;
        text-align: left;
        font-style: italic;
    }
    &__block1{
        background-color: #fff;
        margin-top: 150px;
        max-width: 100%;
        max-height: 800px;
    }
    &__block1-describe1{
        text-indent: 25px;
        padding-top: 30px;
        padding-bottom: 30px;
        font-size: 25px;
        text-align: left;

    }
    &__block2{
        background-color: #fff;
        margin-top: 150px;
        padding-bottom: 10px;
        max-width: 100%;
        max-height: 800px;
    }
    &__block2-describe2{
        padding-top: 30px;
        padding-bottom: 10px;
        font-size: 25px;
        text-align: left;
    }
    &__button{
        margin-top: 50px;
        margin-bottom: 50px;
        display: flex;
        justify-content: center;
    }
    &__btn{
        @extend %btn;
    }
    &__text{
        margin-left:50px;
        
    }
    &__instr{
        background-color: #fff;
        margin-top: 100px;
        max-width: 100%;
        max-height: 100%;
    }
    &__img{
        max-width: 600px;
        max-height: 600px;
     
    }
}
