.ForJkx{
    margin-top: 90px;
    @extend %main__animation;
    &__inner{
        padding-top: 100px;
        padding-bottom: 115px;
    }

    &__title1{
        color: #FFFFFF;
        font-family: 'Montserrat';
        font-style: normal;
        text-align: center;
        font-weight: 700;
        font-size: 40px;
        line-height: 49px;
        margin-bottom: 50px;
    }

    &__title2{
        color: #FFFFFF;
        font-family: 'Montserrat';
        font-style: normal;
        text-align: center;
        font-weight: 700;
        font-size: 28px;
        line-height: 34px;
        margin-bottom: 20px;
    }

    &__describe{
        color: #FFFFFF;
        margin-top: 30px;
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 600px;
        font-size: 22px;
        text-align: center;
        line-height: 28px;
    }
    &__textblock-1{
        padding: 100px 0;
    }
    &__cards{
        margin-top: 50px;
        display: grid;
        grid-template-columns: repeat(2, 560px);
        grid-column-gap: 80px;
        grid-row-gap: 70px;
    }
    &__card{
        max-width: 560px;
        max-height: 600px;
        padding: 21px 20px;
        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
        border-radius: 20px;
        position: relative;
    }
    &__card-content{
        padding-bottom: 60px;
    }
    &__card-content__title{
        font-size: 24px;
        line-height: 29px;
        margin-bottom: 30px;
    }
    &__card-content__descr{
        font-size: 18px;
        line-height: 28px;
    }
    &__card-btn{
        @extend %btn;
        margin-bottom: 0;
        margin-top: 30px;
    }
    &__functional{
        margin-bottom: 100px;
    }
    &__functional-cards{
        display: grid;
        grid-template-columns: repeat(3,380px);
        grid-column-gap: 30px;
    }
    &__card-link{
        position: absolute;
        content: "";
        bottom: 20px;
        left: 20px;
    }
    &__functional-card__text{
        margin-top: 88px;
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 500;
        font-size: 18px;
        line-height: 26px;
        position: relative;
       &::before{
        position: absolute;
        content: "";
        width: 85px;
        height: 5px;
        background-color: $default-color;
        top: -20px;
        left: 0;
       }
    }
}

