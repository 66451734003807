/* Базовые стили */
.Searchmenu {
  display: flex;
  flex-direction: column; /* Устанавливаем столбцовую ориентацию */
  align-items: center; /* Выравниваем по центру по горизонтали */
  width: 100%;
}

.Searchmenu-input-wrapper {
  width: 80%; /* Ширина обертки для поля ввода */
  margin-bottom: 20px; /* Отступ снизу */
}

.Searchmenu .Searchmenu-input-wear-AddressSuggestions {
  margin-top: 60px;
  width: 70%; /* Ширина поля ввода адреса */
  padding: 10px;
  margin-bottom: 15px; /* Отступ снизу */
  border: 1px solid #777777;
  border-radius: 5px;
  box-sizing: border-box;
  font-size: 16px;
}

.Searchmenu .suggestions-container {
  width: 70%; /* Ширина блока с подсказками */
  max-height: 400px; /* Максимальная высота блока с подсказками */
  overflow-y: auto; /* Включаем вертикальную прокрутку при необходимости */
  padding: 10px;
  border: 1px solid #000000;
  border-radius: 5px;
  box-sizing: border-box;
  font-size: 16px;
  color:#000000;
  margin: 20px;
}

.Searchmenu .suggestions-container ul {
  list-style-type: none; /* Убираем маркеры списка */
  padding: 0;
  margin: 0;
}

.Searchmenu .suggestions-container ul li {
  margin-bottom: 5px;
  cursor: pointer;
}

/* Медиа-запросы для адаптивности */
@media only screen and (max-width: 768px) {
  .Searchmenu .Searchmenu-input-wear-AddressSuggestions {
    font-size: 14px;
  }

  .Searchmenu .suggestions-container {
    font-size: 14px;
  }
}

@media only screen and (max-width: 480px) {
  .Searchmenu .Searchmenu-input-wear-AddressSuggestions {
    font-size: 12px;
  }

  .Searchmenu .suggestions-container {
    font-size: 12px;
  }
}
