.Wear_App_Main {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
  }
  
  .Wear_App_Main_button_group {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 30px;
    margin-bottom: 50px;
    justify-content: center;
    animation: fadeInDown 1s ease-in-out;
  }
  
  .Wear_App_Main_h1 {
    font-size: 3rem;
    font-weight: bold;
    color: #333;
    margin-bottom: 50px;
    text-align: center;
    text-transform: uppercase;
    letter-spacing: 2px;
    text-shadow: 2px 2px 5px rgba(0, 0, 0, 0.1);
    animation: fadeInUp 0.7s ease-in-out;

  }
  
  .Wear_App_Main_button {
    padding: 20px 60px;
    font-size: 1.2rem;
    font-weight: bold;
    color: white;
    background-color: #1f487e;
    border: none;
    border-radius: 12px;
    box-shadow: 0px 8px 20px rgba(0, 0, 0, 0.15);
    cursor: pointer;
    transition: all 0.3s ease;
    position: relative;
    overflow: hidden;
    z-index: 1;
  }
  
  .Wear_App_Main_button::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 0;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.3);
    transition: all 0.4s ease;
    z-index: 0;
  }
  
  .Wear_App_Main_button:hover::before {
    width: 100%;
  }
  
  .Wear_App_Main_button:hover {
    background-color: #163a65;
    transform: translateY(-3px);
    box-shadow: 0px 10px 25px rgba(0, 0, 0, 0.2);
  } 
  
  /* Анимации для плавного появления элементов */
  @keyframes fadeInDown {
    from {
      opacity: 0;
      transform: translateY(-30px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
  
  @keyframes fadeInUp {
    from {
      opacity: 0;
      transform: translateY(30px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
  