.not-found-message {
  text-align: center;
  margin: 50px;
  padding: 20px;
  border: 2px solid #2b882b;
  border-radius: 10px;
  box-shadow: 0 0 0.8em 0 rgba(43, 136, 43, 0.8);
  background-color: #2b882b;
  animation: fadeIn 0.5s ease-in-out;
  color: #fff;
  font-weight: bold;
}

.error-icon {
  font-size: 50px;
  margin-bottom: 5px;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}