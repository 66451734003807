.openShiftForm {
    position: fixed; /* Форма фиксирована относительно экрана */
    top: 50%; /* Центрирование по вертикали */
    left: 50%; /* Центрирование по горизонтали */
    transform: translate(-50%, -50%); /* Сдвиг для абсолютного центрирования */
    background-color: #f4f4f8; /* Нежный фон */
    padding: 20px; /* Внутренние отступы */
    border-radius: 10px; /* Скругленные углы */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Легкая тень */
    width: 100%; /* Полная ширина на мобильных */
    max-width: 400px; /* Максимальная ширина формы */
  }
  
  h2 {
    font-size: 1.5rem;
    color: #333;
    margin-bottom: 20px;
    text-align: center;
  }
  
  form {
    width: 100%;
  
    label {
      display: block;
      font-size: 1rem;
      color: #555;
      margin-bottom: 8px;
    }
  
    input {
      width: 100%;
      padding: 10px;
      border: 1px solid #ccc;
      border-radius: 5px;
      font-size: 1rem;
      margin-bottom: 20px;
      transition: border-color 0.3s;
  
      &:focus {
        border-color: #007bff;
        outline: none;
      }
    }
  
    button {
      width: 100%;
      padding: 12px;
      font-size: 1rem;
      color: #fff;
      background-color: #003b6f;
      border: none;
      border-radius: 5px;
      cursor: pointer;
      transition: background-color 0.3s;
  
      &:hover {
        background-color: #0056b3;
      }
    }
  }
  