.ModelTable {
  &__container {
    width: 100%;
    overflow-x: auto;
  }

  background: white;
  border-radius: 5px;
  border-collapse: collapse;
  margin: auto;
  padding: 5px;
  width: 100%;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
  animation: float 5s infinite;
  align-items: center;

  th {
    color: #D5DDE5;
    background: #1f487e;
    border-bottom: 2px solid #a5a7a8;
    border-right: 1px solid #343a45;
    font-size: 16px;
    font-weight: bold;
    padding: 10px;
    text-align: center;
    text-shadow: 0 1px 1px rgba(0, 0, 0, 0.1);
    vertical-align: middle;
  }

  th:first-child {
    border-top-left-radius: 3px;
  }

  th:last-child {
    border-top-right-radius: 3px;
    border-right: none;
  }

  tr {
    border-top: 1px solid #C1C3D1;
    border-bottom: 1px solid #C1C3D1;
    color: #666B85;
    font-size: 16px;
    font-weight: normal;
    text-shadow: 0 1px 1px rgba(256, 256, 256, 0.1);
  }

  tr:first-child {
    border-top: none;
  }

  tr:last-child {
    border-bottom: none;
  }

  tr:nth-child(odd) td {
    background: #EBEBEB;
  }

  tr:last-child td:first-child {
    border-bottom-left-radius: 3px;
  }

  tr:last-child td:last-child {
    border-bottom-right-radius: 3px;
  }

  td {
    background: #FFFFFF;
    padding: 16px;
    text-align: center;
    vertical-align: middle;
    font-weight: 400;
    font-size: 16px;
    text-shadow: -1px -1px 1px rgba(0, 0, 0, 0.1);
    border-right: 1px solid #C1C3D1;
  }

  td:last-child {
    border-right: 0px;
  }

  &__min-font__size {
    direction: ltr;
    position: relative;

    th {
      font-size: 13px;
      text-align: center;
      padding: 15px 1px;
      position: -webkit-sticky;
      /* for Safari */
      position: sticky;
      top: 0;
    }

    tr:nth-child(odd) td {
      background-color: #fff;
    }

    td {
      font-size: 11px;
      font-weight: bold;
      text-align: center;
      padding: 5px 1px;
      color: #000;
      width: 50px;
      position: -webkit-sticky;
      /* for Safari */
      position: sticky;
      left: 0;
    }

    tr .dynamicValuesRso__td-even,
    tr:nth-child(odd) .dynamicValuesRso__td-even {
      background-color: #EBEBEB;
    }

    thead th:first-child,
    tbody td:first-child {
      left: 0;
      z-index: 1;
    }
  }
}

.ModelTable--green {
  thead {
    box-shadow: 0 0 0.8em 0 rgba(5, 173, 55, 0.8);
  }

  th {
    background: green;
  }
}

.ModelTable__container--min {
  direction: rtl;
  overflow: auto;
  width: 100%;
  margin-bottom: 10px;
}

.ModelTable--min {
  direction: ltr;
  position: relative;

  thead th {
    font-size: 13px;
    text-align: center;
    padding: 15px 1px;
    position: sticky;
    top: 0;
  }

  thead th:first-child {
    left: 0;
    z-index: 1;
  }

  tbody td {
    font-size: 11px;
    font-weight: bold;
    text-align: center;
    padding: 5px 1px;
    color: #000;
    width: 50px;
    position: sticky;
    left: 0;
    background-color: #fff !important;
  }

  tbody td:first-child {
    left: 0;
    z-index: 1;
  }

  .td-even {
    background-color: #EBEBEB !important;
  }

}