.switch{
  display: flex;
  justify-content: center;
  align-items: center;
  // margin-bottom: 20px;
}

.switch-input[type=checkbox] {
  height: 0;
  width: 0;
  visibility: hidden;
}

.switch-label {
  cursor: pointer;
  text-indent: -9999px;
  width: 40px;
  height: 20px;
  background: #a5a7a8;
  display: block;
  border-radius: 20px;
  position: relative;
}

.switch-label:after {
  content: '';
  position: absolute;
  top: 1px;
  left: 1px;
  width: 18px;
  height: 18px;
  background: #fff;
  border-radius: 18px;
  transition: 0.3s;
}

.switch-input:checked+.switch-label {
  background: #3d5d99;
}

.switch-input:checked+.switch-label:after {
  left: calc(100% - 2px);
  transform: translateX(-100%);
}

.switch-label:active:after {
  width: 26px;
}

.switch__title-start{
  margin-right: 5px;
}

.switch__title-end{
  margin-left: 5px;
}