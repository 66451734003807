/* DATA */

/* Основной контейнер */
.Public_Data_Main {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  margin-top: 10vh;
  box-sizing: border-box;
  max-width: 100%;
  animation: fadeIn 1s ease-in-out; /* Анимация для контейнера */
}

/* Контейнер для инпутов */
.Public_Data_Inputs {
  width: 100%;
  max-width: 1200px; /* Увеличение максимальной ширины */
  margin-bottom: 25px;
  animation: fadeIn 1s ease-in-out 0.2s; /* Плавное появление инпутов с задержкой */
  animation-fill-mode: both;
}

/* Группа полей ввода */
.Public_Data_Input_Field_Group {
  display: grid;
  grid-template-columns: repeat(3, 1fr); /* Расположение в три столбца */
  gap: 20px; /* Отступ между полями ввода */
}

/* Стили для инпутов */
.Public_Data_Input_Field {
  padding: 20px; /* Увеличение внутреннего отступа */
  border: 1px solid #1d1a1a;
  border-radius: 5px;
  box-sizing: border-box;
  width: 100%;
  transition: border-color 0.3s ease, transform 0.3s ease;
}

/* Если элемент последний в ряду, растянуть его на всю ширину */
.Public_Data_Input_Field:nth-child(3n + 1):nth-last-child(-n + 3),
.Public_Data_Input_Field:nth-child(3n + 2):nth-last-child(-n + 2),
.Public_Data_Input_Field:nth-child(3n):nth-last-child(-n + 1) {
  grid-column: span 3;
}

/* Анимация при фокусе инпутов */
.Public_Data_Input_Field:focus {
  border-color: #007bff;
  transform: scale(1.05);
}

/* Стили для инпута адреса */
.Public_Data_Input_Field_Address {
  grid-column: span 3;
  padding: 20px;
  width: 100%;
  border: 1px solid #1d1a1a;
  border-radius: 5px;
  box-sizing: border-box;
  transition: border-color 0.3s ease, transform 0.3s ease;
}

/* Анимация при фокусе инпута адреса */
.Public_Data_Input_Field_Address:focus {
  border-color: #007bff;
  transform: scale(1.05);
}

/* Заголовок для секции */
.Public_Data_Section_Title {
  margin: 30px 0 15px;
  animation: fadeIn 1s ease-in-out 0.3s; /* Анимация заголовка */
  animation-fill-mode: both;
}

.Public_Data_Section_Span {
  font-size: 1.4em;
  font-weight: bold;
  position: relative;
  margin-bottom: 10px;
  transition: transform 0.3s ease;
}

/* Декоративная линия под заголовком */
.Public_Data_Section_Span::after {
  content: '';
  display: block;
  width: 100%;
  height: 2px;
  background-color: #000;
  position: absolute;
  bottom: -5px;
  left: 0;
  transform: scaleX(0);
  transform-origin: left;
  transition: transform 0.3s ease;
}

.Public_Data_Section_Span:hover::after {
  transform: scaleX(1);
}

/* Группа чекбоксов */
.Public_Data_Checkbox_Group {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  max-width: 1000px;
  animation: fadeIn 0.5s ease-in-out 0.4s; /* Анимация для группы чекбоксов */
  animation-fill-mode: both;
}

/* Стили для чекбоксов и их лейблов */
.Public_Data_Checkbox_Label {
  flex-basis: 45%;
  margin-bottom: 10px;
  transition: transform 0.3s ease;
}

.Public_Data_Checkbox {
  margin-right: 10px;
  transition: transform 0.3s ease;
}

.Public_Data_Checkbox_Label:hover {
  transform: scale(1.05);
}

/* Адаптивность для планшетов и больших мобильных устройств */
@media (max-width: 992px) {
  .Public_Data_Main {
    margin-top: 70px; /* Уменьшение отступа для планшетов */
  }

  .Public_Data_Input_Field_Group {
    grid-template-columns: repeat(2, 1fr); /* Два столбца на планшете */
  }

  .Public_Data_Checkbox_Label {
    flex-basis: 48%; /* Чуть меньше места для чекбоксов */
  }
}

/* Адаптивность для мобильных устройств */
@media (max-width: 576px) {
  .Public_Data_Main {
    margin-top: 50px; /* Ещё меньшее значение для мобильных устройств */
  }

  .Public_Data_Input_Field_Group {
    grid-template-columns: 1fr; /* Один столбец на мобильных устройствах */
  }

  .Public_Data_Inputs {
    padding: 10px;
  }

  .Public_Data_Checkbox_Group {
    justify-content: center;
  }

  .Public_Data_Checkbox_Label {
    flex-basis: 100%; /* Чекбоксы в полный ряд на мобильных */
  }
}


/*CONSTR*/


/* Основной контейнер */
.Public_Constr_Main {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10px;
  box-sizing: border-box;
  max-width: 100%;
  animation: fadeIn 1s ease-in-out; /* Анимация для контейнера */
}

/* Контейнер для инпутов */
.Public_Constr_Data {
  width: 100%;
  max-width: 1000px; /* Уменьшение максимальной ширины */
  margin-bottom: 10px;
  animation: fadeIn 1s ease-in-out 0.2s; /* Плавное появление инпутов с задержкой */
  animation-fill-mode: both;
}

/* Группа полей ввода */
.Public_Constr_Input_Group {
  display: flex;
  flex-wrap: wrap;
  gap: 20px; /* Используем gap для равномерного отступа между полями */
}

/* Стили для инпутов */
.Public_Constr_Input_Field {
  flex: 1 1 calc(50% - 20px); /* Поля занимают 50% ширины минус отступы */
  margin: 10px 5px;
  padding: 20px; /* Уменьшение внутреннего отступа */
  border: 1px solid #1d1a1a;
  border-radius: 5px;
  box-sizing: border-box;
  transition: border-color 0.3s ease, transform 0.3s ease;
}

/* Анимация при фокусе инпутов */
.Public_Constr_Input_Field:focus {
  border-color: #007bff;
  transform: scale(1.05);
}

/* Анимация появления элементов */
@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}


/*INZ*/


/* Основной контейнер */
.Public_Inz_Main {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 20px;
  box-sizing: border-box;
  max-width: 100%;
  animation: fadeIn 1s ease-in-out; /* Анимация для контейнера */
}

/* Контейнер для инпутов */
.Public_Inz_Inputs {
  width: 100%;
  max-width: 1000px; /* Уменьшение максимальной ширины */
  margin-bottom: 25px;
  animation: fadeIn 1s ease-in-out 0.2s; /* Плавное появление инпутов с задержкой */
  animation-fill-mode: both;
}

/* Группа полей ввода */
.Public_Inz_Input_Field_Group {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

/* Стили для инпутов */
.Public_Inz_Input_Field {
  width: calc(50% - 15px); /* Уменьшение ширины инпутов */
  margin: 10px 5px;
  padding: 20px; /* Уменьшение внутреннего отступа */
  border: 1px solid #1d1a1a;
  border-radius: 5px;
  box-sizing: border-box;
  transition: border-color 0.3s ease, transform 0.3s ease;
}

/* Анимация при фокусе инпутов */
.Public_Inz_Input_Field:focus {
  border-color: #007bff;
  transform: scale(1.05);
}

/* Анимация появления элементов */
@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}


/*OPTION */


.Public_Option_Main {
  padding: 20px;
  background-color: #f0f0f5;
  border-radius: 12px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  max-width: 1000px;
  margin-top: 10vh;
  animation: fadeIn 0.8s ease-in-out;
}

.Public_Option_Inputs {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: space-between;
}

.Public_Option_Input_Field_Group {
  display: flex;
  flex-direction: column;
  flex: 1 1 48%;
  min-width: 300px;
}

.Public_Option_Label {
  font-size: 18px;
  font-weight: 500;
  color: #333;
  margin-bottom: 5px;
  margin-top: 15px;
}

.Public_Option_Select_Field, 
.Public_Option_Input_Field {
  padding: 25px;
  font-size: 20px;
  border: 1px solid #ccc;
  border-radius: 8px;
  background-color: #fff;
  transition: all 0.3s ease;
}

.Public_Option_Select_Field:focus,
.Public_Option_Input_Field:focus {
  border-color: #007BFF;
  outline: none;
  box-shadow: 0 0 8px rgba(0, 123, 255, 0.2);
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@media (max-width: 768px) {
  .Public_Option_Inputs {
    flex-direction: column;
  }

  .Public_Option_Input_Field_Group {
    width: 100%;
  }
}


/*WINDOW*/


/* Основные стили для компонента PublicWindow */
.Public_Window_Main {
  max-width: 800px;
  margin: 20px auto;
  padding: 30px;
  background-color: #f7f9fc; /* Светлый фон */
  border-radius: 8px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  animation: fadeIn 1s ease-out;
  overflow-y: auto; /* Добавлен скроллинг */
  max-height: 70vh; /* Ограничение высоты основного контейнера */
}

.Public_Window_Input_Field_Group,
.Public_Window_Custom_Size {
  margin-bottom: 20px;
  opacity: 0;
  animation: fadeIn 1s ease-out forwards;
}

.Public_Window_Input_Field_Group:last-child,
.Public_Window_Custom_Size:last-child {
  margin-bottom: 0;
}

.Public_Window_Label {
  display: block;
  margin-bottom: 8px;
  font-size: 16px;
  color: #47494b; /* Темно-синий цвет для текста */
}

.Public_Window_Select_Field,
.Public_Window_Input_Field {
  width: 100%;
  padding: 17px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 16px;
  transition: border-color 0.3s;
}

.Public_Window_Select_Field:focus,
.Public_Window_Input_Field:focus {
  border-color: #007BFF; /* Синий цвет при фокусе */
  outline: none;
}

.Public_Window_Add_Button {
  background-color: #007BFF; /* Основной синий цвет */
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s;
  margin-top: 10px;
}

.Public_Window_Add_Button:hover {
  background-color: #0056b3; /* Темнее синий при наведении */
}

.Public_Window_Table {
  margin-top: 20px;
  max-height: 300px; /* Ограничение высоты таблицы */
  overflow-y: auto; /* Скроллинг внутри таблицы */
}

.Public_Window_Table h3 {
  font-size: 18px;
  color: #47494b; /* Темно-синий цвет для заголовка таблицы */
}

.Public_Window_Table table {
  width: 100%;
  border-collapse: collapse;
  background-color: #ffffff; /* Белый фон таблицы */
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  overflow: hidden;
}

.Public_Window_Table th,
.Public_Window_Table td {
  padding: 12px;
  text-align: left;
  border-bottom: 1px solid #ddd;
}

.Public_Window_Table th {
  background-color: #007BFF; /* Синий цвет для заголовков таблицы */
  color: white;
}

.Public_Window_Table tr:last-child td {
  border-bottom: none;
}

.Public_Window_Total_Area {
  margin-top: 20px;
  font-size: 18px;
  color: #47494b; /* Темно-синий цвет для общей площади */
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}


/*DOOR*/


/* Основные стили для компонента PublicDoor */
.Public_Door_Main {
  max-width: 800px;
  margin: 20px auto;
  padding: 30px;
  background-color: #f7f9fc; /* Светлый фон */
  border-radius: 8px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  animation: fadeIn 1s ease-out;
  overflow-y: auto; /* Добавлен скроллинг */
  max-height: 70vh; /* Ограничение высоты основного контейнера */
}

.Public_Door_Input_Field_Group,
.Public_Door_Custom_Size {
  margin-bottom: 20px;
  opacity: 0;
  animation: fadeIn 1s ease-out forwards;
}

.Public_Door_Input_Field_Group:last-child,
.Public_Door_Custom_Size:last-child {
  margin-bottom: 0;
}

.Public_Door_Label {
  display: block;
  margin-bottom: 8px;
  font-size: 16px;
  color: #47494b; /* Темно-синий цвет для текста */
}

.Public_Door_Select_Field,
.Public_Door_Input_Field {
  width: 100%;
  padding: 17px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 16px;
  transition: border-color 0.3s;
}

.Public_Door_Select_Field:focus,
.Public_Door_Input_Field:focus {
  border-color: #007BFF; /* Синий цвет при фокусе */
  outline: none;
}

.Public_Door_Add_Button {
  background-color: #007BFF; /* Основной синий цвет */
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s;
  margin-top: 10px;
}

.Public_Door_Add_Button:hover {
  background-color: #0056b3; /* Темнее синий при наведении */
}

.Public_Door_Table {
  margin-top: 20px;
  max-height: 300px; /* Ограничение высоты таблицы */
  overflow-y: auto; /* Скроллинг внутри таблицы */
}

.Public_Door_Table h3 {
  font-size: 18px;
  color: #47494b; /* Темно-синий цвет для заголовка таблицы */
}

.Public_Door_Table table {
  width: 100%;
  border-collapse: collapse;
  background-color: #ffffff; /* Белый фон таблицы */
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  overflow: hidden;
}

.Public_Door_Table th,
.Public_Door_Table td {
  padding: 12px;
  text-align: left;
  border-bottom: 1px solid #ddd;
}

.Public_Door_Table th {
  background-color: #007BFF; /* Синий цвет для заголовков таблицы */
  color: white;
}

.Public_Door_Table tr:last-child td {
  border-bottom: none;
}

.Public_Door_Total_Area {
  margin-top: 20px;
  font-size: 18px;
  color: #47494b; /* Темно-синий цвет для общей площади */
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}






/*****************************************************************************************/

/*Button*/


.NextAndPrefButton_main {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.NextButton_main {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.PrefButton_main {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.NextAndPrefButton_button {
  padding: 12px 24px; /* Уменьшение размера кнопки */
  font-size: 1.1rem;
  font-weight: bold;
  color: white;
  background-color: #1f487e;
  border: none;
  border-radius: 12px;
  box-shadow: 0px 8px 20px rgba(0, 0, 0, 0.15);
  cursor: pointer;
  transition: all 0.3s ease;
  position: relative;
  overflow: hidden;
  z-index: 1;
  margin-right: 20px;
  margin-bottom: 5px;
  transition: transform 0.3s ease, background-color 0.3s ease;
  animation: fadeIn 0.5s ease-in-out 0.5s; 
  animation-fill-mode: both;
}

.NextAndPrefButton_button:hover {
  transform: scale(1.1);
  background-color: #3a7ca5;
}

.AddDatatoHome_button{
  padding: 12px 24px; /* Уменьшение размера кнопки */
  font-size: 1.1rem;
  font-weight: bold;
  color: white;
  background-color: #1f487e;
  border: none;
  border-radius: 12px;
  box-shadow: 0px 8px 20px rgba(0, 0, 0, 0.15);
  cursor: pointer;
  transition: all 0.3s ease;
  position: relative;
  overflow: hidden;
  z-index: 1;
  margin-right: 20px;
  margin-bottom: 5px;
  transition: transform 0.3s ease, background-color 0.3s ease;
  animation: fadeIn 0.5s ease-in-out 0.5s; 
  animation-fill-mode: both;
}

.AddDatatoHome_button:hover{
  transform: scale(1.1);
  background-color: #3a7ca5;
}

.NextButton_button {
  padding: 12px 24px; /* Уменьшение размера кнопки */
  font-size: 1.1rem;
  font-weight: bold;
  color: white;
  background-color: #1f487e;
  border: none;
  border-radius: 12px;
  box-shadow: 0px 8px 20px rgba(0, 0, 0, 0.15);
  cursor: pointer;
  transition: all 0.3s ease;
  position: relative;
  overflow: hidden;
  z-index: 1;
  margin-right: 20px;
  margin-bottom: 5px;
  transition: transform 0.3s ease, background-color 0.3s ease;
  animation: fadeIn 0.5s ease-in-out 0.5s; 
  animation-fill-mode: both;
}

.NextButton_button:hover {
  transform: scale(1.1);
  background-color: #3a7ca5;
}

.PrefButton_button {
  padding: 12px 24px; /* Уменьшение размера кнопки */
  font-size: 1.1rem;
  font-weight: bold;
  color: white;
  background-color: #1f487e;
  border: none;
  border-radius: 12px;
  box-shadow: 0px 8px 20px rgba(0, 0, 0, 0.15);
  cursor: pointer;
  transition: all 0.3s ease;
  position: relative;
  overflow: hidden;
  z-index: 1;
  margin-right: 20px;
  margin-bottom: 5px;
  transition: transform 0.3s ease, background-color 0.3s ease;
  animation: fadeIn 0.5s ease-in-out 0.5s; 
  animation-fill-mode: both;
}

.PrefButton_button:hover {
  transform: scale(1.1);
  background-color: #3a7ca5;
}

/*********************************************************************************************************************************/

/* Анимация плавного появления */
@keyframes fadeIn {
  0% {
    opacity: 0;
    transform: translateY(20px); 
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}


/*****************************************************************************************************************************/

.Button_to_Inz_AddHouse {
  display: flex;
  flex-direction: row;
  justify-content: center;
}


/*****************************************************************************************************************************/

