/* Основной контейнер для всей формы и таблицы */
.container_Wear_ButtonInz {
    padding: 20px;
    background-color: #ffffff;
    border-radius: 12px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.521);
    max-width: 80%;
    overflow: hidden;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: auto;
    margin-bottom: 20px;
  }
  
  /* Стили формы */
  .form_Wear_ButtonInz {
    display: flex;
    flex-direction: column;
    gap: 15px;
    width: 100%;
    margin-bottom: 30px; /* Отступ снизу перед таблицей */
  }
  
  /* Стили для групп элементов формы */
  .form-group_Wear_ButtonInz {
    display: flex;
    flex-direction: column;
    gap: 10px; /* Расстояние между меткой и инпутом */
  }
  
  /* Стили для меток формы */
  .label_Wear_ButtonInz {
    font-weight: 500;
    margin-bottom: 8px;
    color: #333;
    font-size: 16px;
  }
  
  /* Общие стили для инпутов формы */
  .input_Wear_ButtonInz {
    padding: 12px;
    border: 1px solid #ddd;
    border-radius: 6px;
    font-size: 16px;
    width: 100%;
    box-sizing: border-box;
  }
  
  /* Стили для кнопки отправки */
  .submit-button_Wear_ButtonInz {
    padding: 12px 20px;
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 6px;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.3s ease, transform 0.2s ease;
  }
  
  .submit-button_Wear_ButtonInz:hover {
    background-color: #0056b3;
    transform: translateY(-2px);
  }
  
  .submit-button_Wear_ButtonInz:active {
    transform: translateY(0);
  }
  
  /* Контейнер для таблицы */
  .table-container_Wear_ButtonInz {
    width: 100%;
    flex-grow: 1; /* Занимает оставшееся пространство */
    overflow: auto; /* Внутренний скроллинг для таблицы при необходимости */
  }
  
  /* Стили таблицы */
  .table_Wear_ButtonInz {
    width: 100%;
    border-collapse: collapse;
    table-layout: fixed; /* Фиксированная ширина столбцов */
  }
  
  /* Заголовки таблицы */
  .table_Wear_ButtonInz th, .table_Wear_ButtonInz td {
    border: 1px solid #ddd;
    padding: 12px;
    text-align: left;
    font-size: 16px;
    vertical-align: top; /* Выравнивание по верхнему краю */
  }
  
  .table_Wear_ButtonInz th {
    background-color: #f2f2f2;
    font-weight: 600;
  }
  
  .table_Wear_ButtonInz tr:nth-child(even) {
    background-color: #fafafa;
  }
  
  .table_Wear_ButtonInz tr:hover {
    background-color: #f1f1f1;
  }
  
  /* Ограничение максимальной ширины ячеек и перенос текста */
  .table_Wear_ButtonInz td {
    max-width: 200px;
    word-wrap: break-word;
    overflow-wrap: break-word;
  }
  