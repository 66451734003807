.RSODynamicInput {
  max-width: 350px;
  width: 100%;
  margin-bottom: 30px;
  .form-input {
    width: 100%;
    height: 50px;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    font-size: 18px;
    transition: all 0.3s ease;

    &:focus {
      outline: none;
      border-color: #1f487e;
      box-shadow: 0 0 5px #007bff80;
    }
  }

  .form-label {
    font-size: 18px;
    font-weight: bold;
    color: #1f487e;
    pointer-events: none;
    transition: all 0.3s ease;
  }
}