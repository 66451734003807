.hydrologistWorkTable {
  margin-bottom: 20px;
  th {
    padding: 15px 1px;
  }

  input[type="number"],
  input[type="time"],
  input[type="text"] {
    width: 100%;
    padding: 5px;
    box-sizing: border-box;
    border: none;
    background-color: #d2d2d8;
    outline: none;
    border-radius: 3px;
  }

  input[type="number"]:disabled,
  input[type="time"]:disabled,
  input[type="text"]:disabled {
    background-color: transparent;
    cursor: not-allowed;
  }
}