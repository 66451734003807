/* Основной контейнер */
.container_ButtonShowWear {
    padding: 20px;
    background-color: #f7f7f7;
    border-radius: 12px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    max-width: 80%;
    margin: 40px auto;
    text-align: center;
  }
  
  /* Стили для таблицы */
  .table_ButtonShowWear {
    width: 100%;
    border-collapse: collapse;
    background-color: #ffffff;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.473);
    border-radius: 8px;
    overflow: hidden;
    padding: auto;
    text-align: center;
  }
  
  /* Стили для шапки таблицы */
  .thead_ButtonShowWear th {
    background-color: #ffffff;
    color: rgb(0, 0, 0);
    padding: 30px;
    font-weight: bold;
    font-size: 16px;
    border-bottom: 2px solid #000000;
    text-align: center;
    
  }
  
  /* Стили для строк */
  .tr_ButtonShowWear {
    transition: background-color 0.3s ease;
  }
  
  .tr_ButtonShowWear:nth-child(even) {
    background-color: #f2f2f2;
  }
  
  .tr_ButtonShowWear:hover {
    background-color: #eaeaea;
  }
  
  /* Стили для ячеек таблицы */
  .td_ButtonShowWear {
    padding: 30px;
    border-bottom: 1px solid #ddd;
    font-size: 16px;
    color: #333;
    text-align: center;
    word-wrap: break-word;
  }
  
  .td_ButtonShowWear:first-child {
    font-weight: bold;
  }
  
  /* Добавление плавности при наведении */
  .th_ButtonShowWear, .td_ButtonShowWear {
    transition: background-color 0.3s ease, color 0.3s ease;
  }
  
  /* Максимальная ширина ячеек */
  .td_ButtonShowWear {
    max-width: 300px;
  }
  