.RSODynamicButtonGroup {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  margin-bottom: 40px;
}

.RSODynamicButtonGroup-btn {
  font-size: 18px;
  padding: .5em 1em;
  background: #fff;
  color: #4A5568;
  border: 0px solid #A0AEC0;
  margin: .1em;
  transition: background .2s ease, color .2s ease, box-shadow .2s ease, transform .2s ease;
  box-shadow: 0 0 0 #BEE3F8;
  transform: translateY(0);

  &.selected {
    font-weight: bold;
    text-transform: uppercase;
    font-style: italic;
    background: #3d5d99;
    color: #fff;
    box-shadow: 0 0 0.8em 0 rgba(61, 93, 153, .8);
  }

  &.unselected {
    font-size: 17px;
  }

  &--green.selected {
    background-color: #2b882b;
    box-shadow: 0 0 0.8em 0 rgba(5, 173, 55, .8);
  }

  &--green.unselected {
    background-color: #37ad37;
  }
}

.RSODynamicButtonGroup-btn--green {
  background: green;
  color: #fff;
}

.RSODynamicButtonGroup-btn:first-of-type {
  border-radius: .5em 0 0 .5em;
}

.RSODynamicButtonGroup-btn:last-of-type {
  border-radius: 0 .5em .5em 0;
}

.RSODynamicButtonGroup-btn i {
  color: #A0AEC0;
  margin-right: .3em;
  transition: all .2s ease-out;
}

.RSODynamicButtonGroup:hover .RSODynamicButtonGroup-btn {
  color: #A0AEC0;
}

.RSODynamicButtonGroup:hover .RSODynamicButtonGroup-btn:hover {
  background: #3d5d99;
  color: #fff;
  box-shadow: 0 0 0.8em 0 rgba(61, 93, 153, .8);
  transform: translateY(-.2em);
}

.RSODynamicButtonGroup--green:hover .RSODynamicButtonGroup-btn--green:hover {
  background-color: rgb(55, 173, 55);
  color: #fff;
  box-shadow: 0 0 0.8em 0 rgba(5, 173, 55, .8);
  transform: translateY(-.2em);
}

.RSODynamicButtonGroup:hover .RSODynamicButtonGroup-btn--green:hover {
  background-color: #2b882b;
}

.RSODynamicButtonGroup:hover .RSODynamicButtonGroup-btn i {
  color: #CBD5E0;
}

.RSODynamicButtonGroup:hover .RSODynamicButtonGroup-btn:hover i {
  color: #FED7E2;
  transform: rotate(-10deg);
}